import React from 'react';
import Modal from 'react-modal';

const EveryoneModal = ({ isOpen, onRequestClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Everyone Details"
      className="fixed inset-0 flex items-center justify-center z-50"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50"
    >
      <div className="bg-white rounded-lg p-8 w-11/12 max-w-4xl max-h-[90vh] overflow-y-auto">
        <h2 className="text-3xl font-bold mb-6">For Everyone</h2>
        <p className="text-gray-700 mb-6 text-lg">
          Trusted partner with vast experience to provide guidance and support throughout the product lifecycle and offer visibility.
        </p>
        <div className="mb-6">
          <h3 className="text-xl font-semibold mb-3">Key Benefits:</h3>
          <ul className="list-disc list-inside space-y-2">
            <li>Experienced and trusted partner</li>
            <li>End-to-end product lifecycle support</li>
            <li>Guidance and expertise at every stage</li>
            <li>Increased visibility and transparency</li>
          </ul>
        </div>
        <div className="mb-6">
          <h3 className="text-xl font-semibold mb-3">Our Approach:</h3>
          <ol className="list-decimal list-inside space-y-2">
            <li>Understand your product vision and requirements</li>
            <li>Assemble the right team of clients and vendors</li>
            <li>Provide oversight and guidance throughout the project</li>
            <li>Ensure quality and timely delivery</li>
            <li>Offer ongoing support and maintenance</li>
          </ol>
        </div>
        <button
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-6 rounded-lg focus:outline-none focus:shadow-outline text-lg"
          onClick={onRequestClose}
        >
          Close
        </button>
      </div>
    </Modal>
  );
};

export default EveryoneModal;
