import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";
import { apiSlice } from "../../api/apiSlice"

const companyAdapter = createEntityAdapter({})

const initialState = companyAdapter.getInitialState()

export const companyApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getCompanies: builder.query({
            // note: an optional `queryFn` may be used in place of `query`
            query: () => ({
                url: '/admin/companies',
                header: {'content-type': 'application/json', 
                    'Access-Control-Allow-Origin': '*', 
                    'Access-Control-Allow-Credentials': "true"
                },
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            }),
            // Keep unused data for 5 seconds - cache in RTK query - default is 60 seconds
            // configuration for an individual endpoint, overriding the api setting
            keepUnusedDataFor: 5, // value in seconds
            refetchOnFocus: true,
            refetchOnReconnect: true,
            // Pick out data and prevent nested properties in a hook or selector
            transformResponse: response => {
                 return response;
            },
            // Pick out errors and prevent nested properties in a hook or selector
            transformErrorResponse: (response, meta, arg) => response.status,
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'Company', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'Company', id }))
                    ]
                } else return [{ type: 'Company', id: 'LIST' }]
            }
        }),
        getCompanyByName: builder.query({
            query: (name) => ({
                url: `/admin/company/${name}`
            }),
            transformResponse : response => {
                return response;
            }

        }),
        getCompanyDetails: builder.query({
            query: (id) => ({
                url: `/admin/company/${id}`
            }),
            transformResponse : response => {
                return response;
            }

        }),
        addNewCompany: builder.mutation({
            query: initialCompanyData => ({
                url: '/admin/addcompany',
                    method: 'POST',
                    body: {
                        ...initialCompanyData,
                    }
            }),
            transformResponse: response => {
                 return response;
            },
            invalidatesTags: [
                { type: 'Company', id: "LIST" }
            ]
        }),
        updateCompany: builder.mutation({
            query: initialCompanyData => ({
                url: '/companies',
                method: 'PATCH',
                body: {
                    ...initialCompanyData,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Company', id: arg.id }
            ]
        }),
        deleteCompany: builder.mutation({
            query: ({ id }) => ({
                url: `/companies`,
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Company', id: arg.id }
            ]
        }),
    }),
})

export const {
    useGetCompaniesQuery,
    useGetCompanyByNameQuery,
    useGetCompanyDetailsQuery,
    useAddNewCompanyMutation,
    useUpdateCompanyMutation,
    useDeleteCompanyMutation,
} = companyApiSlice

// returns the query result object
export const selectCompaniesResult = companyApiSlice.endpoints.getCompanies.select()

// creates memoized selector
const selectCompaniesData = createSelector(
    selectCompaniesResult,
    companiesResult => companiesResult.data // normalized state object with ids & entities
)

// getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllCompanies,
    selectById: selectCompanyById,
    selectIds: selectCompanyIds
    // Pass in a selector that returns the users slice of state
} = companyAdapter.getSelectors(state => selectCompaniesData(state) ?? initialState)
