import React from 'react';
import Modal from 'react-modal';

const VendorModal = ({ isOpen, onRequestClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Vendor Details"
      className="fixed inset-0 flex items-center justify-center z-50"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50"
    >
      <div className="bg-white rounded-lg p-8 w-11/12 max-w-4xl max-h-[90vh] overflow-y-auto">
        <h2 className="text-3xl font-bold mb-6">For Vendors</h2>
        <p className="text-gray-700 mb-6 text-lg">
          Access to a network of clients to promote services offered and credibility to attract more customers.
        </p>
        <div className="mb-6">
          <h3 className="text-xl font-semibold mb-3">Key Benefits:</h3>
          <ul className="list-disc list-inside space-y-2">
            <li>Access to a curated network of potential clients</li>
            <li>Showcase your expertise and services</li>
            <li>Gain credibility and attract more customers</li>
            <li>Streamlined project acquisition process</li>
          </ul>
        </div>
        <div className="mb-6">
          <h3 className="text-xl font-semibold mb-3">How It Works:</h3>
          <ol className="list-decimal list-inside space-y-2">
            <li>Create your vendor profile and showcase your services</li>
            <li>We match you with clients based on their project requirements</li>
            <li>Clients review your profile and select preferred vendors</li>
            <li>Collaborate with clients and deliver high-quality projects</li>
            <li>Build your reputation and attract more clients</li>
          </ol>
        </div>
        <button
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-6 rounded-lg focus:outline-none focus:shadow-outline text-lg"
          onClick={onRequestClose}
        >
          Close
        </button>
      </div>
    </Modal>
  );
};

export default VendorModal;