import React, { useState } from "react";
import { MagnifyingGlassIcon, ChevronUpDownIcon } from "@heroicons/react/24/outline";
import { 
  PencilIcon, 
  EllipsisHorizontalIcon,
  UserPlusIcon } 
from "@heroicons/react/24/solid";
import {
  Card,
  CardHeader,
  Input,
  Typography,
  Button,
  CardBody,
  Chip,
  CardFooter,
  Tabs,
  TabsHeader,
  Tab,
  IconButton,
  Tooltip,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
} from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";

// Change this to get Companies query 
import { useGetCompaniesQuery } from "../../../features/companies/companyApiSlice";

import DefaultLayout from '../../../layouts/DefaultLayout';
import Breadcrumb from '../../../components/Breadcrumb';

// TODO: move to constants
const TABS = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Clients",
    value: "clients",
  },
  {
    label: "Vendors",
    value: "vendors",
  },
];

// TODO: move to constants
const TABLE_HEAD = ["Name", "Country", "Primary Skill 1", "Primary Skill 2", "Primary Skill 3", "Type"];
 
 
export function CompaniesTable() {

  const {
    data: companies,
    isLoading,
    isSuccess,
    isError,
    error
  } = useGetCompaniesQuery('companiesTable', {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true
  })

  const navigate =  useNavigate();

  const [activeTab, setTab] = useState("all");
  

  const handleSubmit = (e) => {
    e.preventDefault(); // so page doesn't reload when submitting
    navigate("/auth/addcompany")
    
  }

  let content;

  if (isLoading) {
    content = <p>"Loading..."</p>;
  } else if (isSuccess) {
    content = (
      <DefaultLayout>
      <Breadcrumb pageName='View Companies' />
      <Card className="h-full w-full">
        <CardHeader floated={false} shadow={false} className="rounded-none">
          <div className="mb-8 flex items-center justify-between gap-8">
            <div>
              <Typography variant="h5" color="blue-gray">
                Company list
              </Typography>
              <Typography color="gray" className="mt-1 font-normal">
                See information about all companies onboarded till date. 
              </Typography>
            </div>
            <div className="flex shrink-0 flex-col gap-2 sm:flex-row">
              <Button variant="outlined" size="sm">
                view all
              </Button>
              <Button 
                className="flex items-center gap-3" 
                size="sm"
                onClick={handleSubmit}
              >
                <UserPlusIcon strokeWidth={2} className="h-4 w-4" /> Add New
              </Button>
            </div>
          </div>
          <div className="flex flex-col items-center justify-between gap-4 md:flex-row">
            <Tabs value="all" className="w-full md:w-max">
              <TabsHeader>
                {TABS.map(({ label, value }) => (
                  <Tab key={value} value={value} onClick={(e) => {setTab(value)}}>
                    &nbsp;&nbsp;{label}&nbsp;&nbsp;
                  </Tab>
                ))}
              </TabsHeader>
            </Tabs>
            <div className="w-full md:w-72">
              <Input
                label="Search"
                icon={<MagnifyingGlassIcon className="h-5 w-5" />}
              />
            </div>
          </div>
        </CardHeader>
        <CardBody className="overflow-scroll px-0">
          
          <table className="mt-4 w-full min-w-max table-auto text-left">
            <thead>
              <tr>
                {TABLE_HEAD.map((head, index) => (
                  <th
                    key={head}
                    className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                  >
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal leading-none opacity-70"
                    >
                      {head}{" "}
                      {index !== TABLE_HEAD.length - 1 && (
                        <ChevronUpDownIcon strokeWidth={2} className="h-4 w-4" />
                      )}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {companies.map(            
                  ({ companyname, country, primaryskill1, primaryskill2, primaryskill3, ctype }, index) => {
                  const isLast = index === companies.length - 1;
                  const classes = isLast
                    ? "p-4"
                    : "p-4 border-b border-blue-gray-50";
  
                  return (
                    <tr key={companyname} hidden={ (activeTab ==="clients" && ctype === "vendor") || (activeTab ==="vendors" && ctype === "client" )}> 
                      <td className={classes}>
                        <div className="flex items-center gap-3">
                          {/*<Avatar src={img} alt={name} size="sm" />*/}
                          <div className="flex flex-col">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal opacity-70"
                            >
                              {companyname}
                            </Typography>
                          </div>
                        </div>
                      </td>
                      <td className={classes}>
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {country}
                          </Typography>
                        </div>
                      </td>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {primaryskill1}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {primaryskill2}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {primaryskill3}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {ctype}
                        </Typography>
                      </td>

                    </tr>
                  );
                }
              )}
            </tbody>
          </table>

        </CardBody>
        <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
          <Typography variant="small" color="blue-gray" className="font-normal">
            Page 1 of 10
          </Typography>
          <div className="flex gap-2">
            <Button variant="outlined" size="sm">
              Previous
            </Button>
            <Button variant="outlined" size="sm">
              Next
            </Button>
          </div>
        </CardFooter>
      </Card>
      </DefaultLayout>
    )
  } else if (isError) {
    content = <p>{JSON.stringify(error)}</p>;
  }

  return content
}