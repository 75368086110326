import React, { useEffect, useState } from 'react';
import { 
  Radio,
  Input,
  Button,
  Typography,
  Card,
  List,
  ListItem,
  ListItemPrefix,
} from "@material-tailwind/react";
import DefaultLayout from '../../layouts/DefaultLayout';
import Breadcrumb from '../../components/Breadcrumb';
import { Link, useNavigate } from 'react-router-dom';
import { useAddNewRfpMutation } from '../../features/client/clientApiSlice';
import DataEntry from '../../components/svgs/DataEntry';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      className="h-full w-full scale-105"
    >
      <path
        fillRule="evenodd"
        d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
        clipRule="evenodd"
      />
    </svg>
  );
}


const json = {
  question: 'Existing Project?',
  choices:
  [
    { text: 'Yes', value: 'Yes' },
    { text: 'No', value: 'No' }
  ]
}


const NewProposalRequest = () => {

  const [addNewRFP, {
    isLoading,
    isSuccess,
  }] = useAddNewRfpMutation()

  const [description, setdescription] = useState('');
  const [duration, setDuration] = useState('');
  const [budget, setBudget] = useState('');
  const [domain, setDomain] = useState('');
  const [preferredTechnology, setPreferredTechnology] = useState('');
  const navigate = useNavigate();
  const [existingProject, setExistingProject] = useState(false);
  const [status, setStatus] = useState('new');

  const canSave = !isLoading

  const handleSubmit = async (e) => {
    e.preventDefault();

    if(canSave){
      let id = 0;
      addNewRFP({                
        id: id,
        description,
        duration: parseInt(duration),
        budget: parseFloat(budget),
        domain,
        preferredTechnology,
        existingProject,
        status
      })
    }
  };

  useEffect(() => {
    if(isSuccess){
      setdescription('');
      setDuration('');
      setBudget('');
      setDomain('');
      setPreferredTechnology('');
      setExistingProject(false);
      setStatus('');
      navigate('/dashboard/projects');
    }
  }, [isSuccess, navigate])


function ExistingProjectOption ({options, selected, onChange }) {    
  return (
    <div className="flex w-full flex-col gap-1">        
    <Typography variant="h6" color="blue">
      Existing Project
    </Typography>
    <Card className="w-full max-w-[24rem]">
      <List className="flex-row">
        {options.map((choice, index) => (
          <ListItem className="p-0">
            <label
              htmlFor="existing-project-yes"
              className="flex w-full cursor-pointer items-center px-3 py-2"
            >
              <ListItemPrefix className="mr-3">
              <Radio
                name="existing-project"
                id="existing-project-yes"
                ripple={false}
                icon={<Icon />}
                className="hover:before:opacity-0"
                containerProps={{
                  className: "p-0",
                }}
                value={choice.value}
                checked={existingProject === choice.value}
                onChange={(e) => setExistingProject(e.target.value)}
              />
            </ListItemPrefix>
            <Typography
              color="blue-gray"
              className="font-medium text-blue-gray-400"
            >
              {choice.value}
            </Typography>
            </label>
          </ListItem>
        ))}
      </List>
    </Card>
    {/* used for debugging purposes only!
    <div>
        Selected option is : {selected}
    </div>
    */}
    </div>
);
};
    
return (
  <DefaultLayout>
    <Breadcrumb pageName="RFP Request"/>            
      <div className='rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark'>
        <div className='flex flex-wrap items-center'>
          <div className='hidden w-full xl:block xl:w-1/2'>
            <div className='py-17.5 px-26 text-center'>
              <Link className='mb-5.5 inline-block' to='/'>
              <span className="text-2xl font-bold text-blue-600 dark:hidden">
                XConnections
              </span>  
              <span className="text-2xl font-bold text-blue-400 hidden dark:block">
                XConnections
              </span>
              </Link>
              <p className='2xl:px-20'>
                Welcome. Please enter the new project details to request for proposals.
              </p>
              <DataEntry />
              </div>
          </div>
          <div className='w-full border-stroke dark:border-strokedark xl:w-1/2 xl:border-l-2'>
          <div className='w-full p-2 sm:p-12.5 xl:p-10'>
            <h2 className='mb-9 text-2xl font-bold text-black dark:text-white sm:text-title-xl2'>
              New RFP Request
            </h2>
            <form onSubmit={handleSubmit} className="space-y-4">
              {/* start project description input */}
              <div className="flex w-full flex-col gap-1">
                <Typography variant="h6" color="blue">
                  Project Description
                </Typography>
                <Input
                  id="description"
                  type="text"
                  label="Enter project description here"
                  color="blue"
                  required
                  icon={<i className="fa-solid fa-book" />}
                  value={description}
                  onChange={(e) => setdescription(e.target.value)}
                  placeholder='Enter project description here'
                />
              </div>
              {/* end project description input */}
              {/* start duration input */}
              <div className="flex w-full flex-col gap-1">
                <Typography variant="h6" color="blue">
                  Duration (in weeks)
                </Typography>
                <Input
                  type="number"
                  label="Enter duration in weeks"
                  icon={<i className="fa-solid fa-calendar-week"/>}
                  value={duration}
                  onChange={(e) => setDuration(e.target.value)}
                  placeholder='Enter duration in weeks'
                />
              </div>
              {/* end duration input */}
              {/* start budget input */}
              <div className="flex w-full flex-col gap-1">
                <Typography variant="h6" color="blue">
                  Budget
                </Typography>
                <Input
                  type="number"
                  label="Enter budget in USD (approx.)"
                  icon={<i className="fa-solid fa-money-bill"/>}
                  value={budget}
                  onChange={(e) => setBudget(e.target.value)}
                  placeholder='Enter budget in USD (approx.)'
                />
              </div>
              {/* end budget input */}
              {/* start domain input */}
              <div className="flex w-full flex-col gap-1">
                <Typography variant="h6" color="blue">
                  Domain of the Project
                </Typography>
                <Input 
                  label="Enter domain of the project (e.g. IT, Health, etc...)"
                  required
                  icon={<i className="fa-solid fa-microchip" />}
                  value={domain}
                  onChange={(e) => setDomain(e.target.value)}
                  placeholder='Enter domain of the project (e.g. IT, Health, etc...)'
                />
              </div>
              {/* end domain input */}
              {/* start preferred technology input */}
              <div className="flex w-full flex-col gap-1">
                <Typography variant="h6" color="blue">
                  Preferred Technology
                </Typography>
                <Input 
                  label="Enter preferred technology"
                  color="blue"
                  icon={<i className="fa-solid fa-microchip" />}
                  value={preferredTechnology}
                  onChange={(e) => setPreferredTechnology(e.target.value)}
                  placeholder='Enter preferred technology'
                />
              </div>
              {/* end preferred technology input */}                    
              <ExistingProjectOption 
                options={json.choices}
                selected={existingProject}
                onChange={setExistingProject}
              />
              <Button
                className="flex items-center gap-3" 
                variant="gradient" 
                color={description && domain ? "gray" : "blue"}
                disabled={!(description && domain)}
                onClick={handleSubmit}
              >
                <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 19V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v13H7a2 2 0 0 0-2 2Zm0 0a2 2 0 0 0 2 2h12M9 3v14m7 0v4"/>
                </svg>
                Submit 
              </Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </DefaultLayout>
  );
};

export default NewProposalRequest;