import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll'

const navigation = [
  { name: 'About Us', href: '/aboutus', current: false },
  { name: 'Terms', href: '/terms', current: false },
  { name: 'Contact Us', href: '/contactus', current: false },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function HomeNavbar() {
  return (
    <Disclosure as="nav" className="bg-white shadow-sm w-full">
      {({ open }) => (
        <>
          <div className="w-full px-4 sm:px-6 lg:px-8">
            <div className="flex h-16 items-center justify-between">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <Link to="/" className="flex-shrink-0">
                    <span className="text-3xl font-extrabold text-blue-600 hover:text-blue-700 transition duration-300 font-custom">
                      XConnections
                    </span>                    
                  </Link>
                </div>
              </div>
              <div className="hidden sm:flex sm:items-center">
                <div className="flex space-x-4">
                  {navigation.map((item) => (
                    item.name === 'Contact Us' ? (
                      <AnchorLink 
                        key={item.name}
                        href='#contactus'
                        className={classNames(
                          item.current ? 'bg-gray-900 text-white' : 'text-gray-900 hover:bg-blue-700 hover:text-white',
                          'rounded-md px-3 py-2 text-sm font-medium'
                        )}
                      >
                        <i className="fas fa-envelope mr-2"></i>
                        Contact Us
                      </AnchorLink>
                    ) : (
                      <a
                        key={item.name}
                        href={item.href}
                        target="_blank"
                        rel="noreferrer"
                        aria-current={item.current ? 'page' : undefined}
                        className={classNames(
                          item.current ? 'bg-gray-900 text-white' : 'text-gray-900 hover:bg-blue-700 hover:text-white',
                          'rounded-md px-3 py-2 text-sm font-medium'
                        )}
                      >
                        {item.name} 
                      </a>
                    )
                  ))}
                </div>
              </div>
              <div className="hidden sm:flex sm:items-center">
                <Link
                  to="/login"
                  className="text-gray-900 hover:bg-blue-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium"
                >
                  Login
                </Link>
              </div>
              <div className="flex items-center sm:hidden">
                <DisclosureButton className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span className="sr-only">Open main menu </span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </DisclosureButton>
              </div>
            </div>
          </div>

          <DisclosurePanel className="sm:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2">
              {[...navigation, { name: 'Login', href: '/login', current: false }].map((item) => (
                <DisclosureButton
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item.current ? 'bg-indigo-50 border-indigo-500 text-indigo-700' : 'border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700',
                    'block border-l-4 py-2 pl-3 pr-4 text-base font-medium'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </DisclosureButton>
              ))}
            </div>
          </DisclosurePanel>
        </>
      )}
    </Disclosure>
  )
}
