import type { Column } from "./BoardColumn.tsx";

/*
const defaultCols = [
    {
      id: "todo" as const,
      title: "Todo",
    },
    {
      id: "in-progress" as const,
      title: "In progress",
    },
    {
      id: "done" as const,
      title: "Done",
    },
  ] satisfies Column[];
*/

const defaultCols = [
  {
    id: "new-rfp" as const,
    title: "New RFP",
  },
  {
    id: "requirement-analysis" as const,
    title: "Requirement Analysis",
  },
  {
    id: "vendor-notification" as const,
    title: "Vendor Notification",
  },
  {
    id: "vendor-response" as const,
    title: "Vendor Response",
  },
  {
    id: "vendor-discussion" as const,
    title: "Vendor Discussion",
  },
  {
    id: "top-vendor-review" as const,
    title: "Top Vendor Review",
  },
  {
    id: "vendors-to-client" as const,
    title: "Propose Vendors to client",
  },
  {
    id: "client-selection" as const,
    title: "Client Selection",
  },
  {
    id: "contracts-selection" as const,
    title: "Contracts Selection",
  },
  {
    id: "new-project-kickoff" as const,
    title: "New Project - kick Off",
  },
  {
    id: "rfp-archive" as const,
    title: "RFP Archive",
  },

] satisfies Column[];

export default defaultCols