import React from 'react';
import type { UniqueIdentifier } from "@dnd-kit/core";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Card, CardContent, CardHeader } from "../kanban/ui/card.tsx";
import { Button } from "../kanban/ui/button.tsx";
import { cva } from "class-variance-authority";
import { GripVertical } from "lucide-react";
import { Badge } from "../kanban/ui/badge.tsx";
import { ColumnId } from "./KanbanBoard.tsx";


export interface Task {
    id: UniqueIdentifier;
    description: string;
    duration: string;
    budget: number;
    preferredTechnology: string;
    existingProject: string;
    status: string;
    domain: string;
    //index: UniqueIdentifier;
    sequence: number;
}

interface TaskCardProps {
  task: Task;
  isOverlay?: boolean;
}

export type TaskType = "Task";

export interface TaskDragData {
  type: TaskType;
  task: Task;
}


export function TaskCard({ task, isOverlay }: TaskCardProps) {
  const {
    setNodeRef,
    attributes,
    listeners,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: task.id,
    data: {
      type: "Task",
      task,
    } satisfies TaskDragData,
    attributes: {
      roleDescription: "Task",
    },
  });

  const style = {
    transition,
    transform: CSS.Translate.toString(transform),
  };

  const variants = cva("", {
    variants: {
      dragging: {
        over: "ring-2 ring-blue-300 opacity-70",
        overlay: "ring-2 ring-blue-500",
      },
    },
  });

  return (
    <Card
      ref={setNodeRef}
      style={style}
      className={variants({
        dragging: isOverlay ? "overlay" : isDragging ? "over" : undefined,
      })}
    >
      <CardHeader className="bg-blue-100 rounded-t-xl px-3 py-2 flex flex-row items-center justify-between border-b border-blue-200">
        <Button
          variant={"ghost"}
          {...attributes}
          {...listeners}
          className="p-1 text-blue-600 -ml-2 h-auto cursor-grab bg-blue-50 hover:bg-blue-200 transition-colors"
        >
          <span className="sr-only">Move task</span>
          <GripVertical size={16} />
        </Button>
        <Badge variant={"outline"} className="bg-white text-blue-700 border-blue-300 font-medium text-xs">
          {task.duration}
        </Badge>
      </CardHeader>
      <CardContent className="bg-white rounded-b-xl px-3 pt-2 pb-3 text-left">
        <h3 className="text-sm font-semibold text-blue-800 mb-1 truncate">{task.description}</h3>
        <div className="text-xs text-gray-600">
          <p>Budget: ${task.budget.toLocaleString()}</p>
          <p>Tech: {task.preferredTechnology}</p>
          <p>Domain: {task.domain}</p>
        </div>
      </CardContent>
    </Card>
  );
}