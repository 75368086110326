import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Typography,
  Button,
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import { PageTitle } from "../../widgets/layout/page-title";
import { Footer } from "../../widgets/layout/footer";
import { contactData } from "../../data";
import ServicesImage from "../../assets/images/teams/connection.jpeg";
import ConnectPartners from "../../assets/images/teams/connect.jpeg";
import XContactForm from "./XContactForm";
import { GridCard } from "../../widgets/cards/grid-card";


export function HomePage() {
  const [open, setOpen] = useState(0);
  const [alwaysOpen, setAlwaysOpen] = useState(true);

  const handleAlwaysOpen = () => setAlwaysOpen((cur) => !cur);
  const handleOpen = (value) => setOpen(open === value ? 0 : value);

  return (
    <>
      <HeroSection />
      <GridCard />
      <ServicesSection 
        open={open} 
        alwaysOpen={alwaysOpen} 
        handleOpen={handleOpen} 
        handleAlwaysOpen={handleAlwaysOpen} 
      />
      <TeamSection />
      <ContactSection contactData={contactData} />
      <Footer />
    </>
  );
}

function HeroSection() {
    return (
      <section className="relative h-screen bg-cover bg-center bg-no-repeat bg-[url('../../assets/images/backgrounds/home-background2.jpg')]">
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>
        <div className="relative flex h-full items-center justify-center px-4 text-center text-white">
          <div className="max-w-3xl">
            <Typography variant="h1" className="mb-4 text-5xl font-bold">
              Partners in Your Success
            </Typography>
            <Typography variant="h3" className="mb-6 text-2xl font-light">
              Revolutionizing how 
                <span className="text-yellow-400"> small to </span>
                <span className="text-yellow-400">
                    medium sized tech companies find 
                </span>
                <span className="text-yellow-400"> reliable service providers.</span>
            </Typography>
            <Typography variant="lead" className="mb-8">
              We are a trusted resource connecting top-rated vendors with clients, facilitating seamless interactions for informed decisions.
            </Typography>
            <Link 
              to="/aboutus" 
              reloadDocument
              onClick={(event) => {
                event.preventDefault();
                window.open("/aboutus", "_blank");
              }}
            >
              <Button color="white" size="lg" ripple="light">
                Learn More
              </Button>
            </Link>
          </div>
        </div>
      </section>
    );
  }
  

function ServicesSection({ open, alwaysOpen, handleOpen, handleAlwaysOpen }) {
  return (
    <section className="bg-white py-20">
      <div className="container mx-auto px-4">
        <Typography variant="h2" className="mb-12 text-center text-3xl font-bold">
          Our Services
        </Typography>
        <div className="flex flex-wrap items-center">
          <div className="w-full md:w-1/2 pr-4">
            <Accordion open={alwaysOpen}>
              <AccordionHeader onClick={handleAlwaysOpen}>Why work with XConnections?</AccordionHeader>
              <AccordionBody>
                Platform built with a focus on specific needs of clients and specialized skillset of the vendors.
              </AccordionBody>
            </Accordion>
            <Accordion open={open === 1}>
              <AccordionHeader onClick={() => handleOpen(1)}>Our platform</AccordionHeader>
              <AccordionBody>
                Online platform for matching clients and vendors and document their needs, interactions and monitor progress.
              </AccordionBody>
            </Accordion>
            <Accordion open={open === 2}>
              <AccordionHeader onClick={() => handleOpen(2)}>What do we offer?</AccordionHeader>
              <AccordionBody>
                Guidance, oversight and project management by experienced independent staff.
              </AccordionBody>
            </Accordion>
            <div className="mt-8">
              <Button variant="outlined" color="blue" ripple="light">
                Read More
              </Button>
            </div>
          </div>
          <div className="w-full md:w-1/2 mt-12 md:mt-0">
            <Card className="overflow-hidden">
              <CardHeader floated={false} className="h-80">
                <img src={ServicesImage} alt="Our Services" className="h-full w-full object-cover" />
              </CardHeader>
              <CardBody>
                <Typography variant="h5" color="blue-gray" className="mb-2">
                  Comprehensive Services
                </Typography>
                <Typography>
                  We empower clients to make confident decisions when choosing vendor providers through our seamless interaction platform.
                </Typography>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </section>
  );
}

function TeamSection() {
  return (
    <section className="bg-gray-100 py-20">
      <div className="container mx-auto px-4">
        <PageTitle heading="Our Team">
          <Typography variant="lead" color="black" className="opacity-80">
            Our company's commitment lies in driving unparalleled customer satisfaction and promoting an unbiased approach to connecting 
            clients with specialized service vendors. The founding team members have extensive experience in software development, 
            product development, project and vendor management.
          </Typography>
        </PageTitle>
        {/* Add team member cards here when ready */}
      </div>
    </section>
  );
}

function ContactSection({ contactData }) {
  return (
    <section className="bg-white py-20">
      <div className="container mx-auto px-4">
        <Typography variant="h2" className="mb-12 text-center text-3xl font-bold">
          Get in Touch
        </Typography>
        {/* 2 cards per row */}
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 mb-16 max-w-4xl mx-auto">
        {/* all cards on same row */}
        {/*<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 mb-16">*/}
        {/* 3 cards per row */}
        {/*<div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">*/}
          {contactData.map(({ title, icon, description }) => (
            <Card key={title} className="text-center flex flex-col h-full">
              <CardBody className="flex-grow flex flex-col justify-between">
                <div>
                  <div className="mx-auto mb-4 grid h-14 w-14 place-items-center rounded-full bg-blue-100 shadow-lg">
                    {React.createElement(icon, { className: "w-6 h-6 text-blue-600" })}
                  </div>
                  <Typography variant="h5" color="blue-gray" className="mb-2">
                    {title}
                  </Typography>
                </div>
                <Typography className="font-normal text-blue-gray-600">
                  {description}
                </Typography>
              </CardBody>
            </Card>
          ))}
        </div>
        <XContactForm sectionId="contactus" />
      </div>
    </section>
  );
}


export default HomePage;