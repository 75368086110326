import React, {useState, useRef, useEffect } from "react";
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCredentials } from "../../features/auth/authSlice";
import { useLoginMutation } from "../../features/auth/authApiSlice";
import usePersist from "../../hooks/usePersist";
import PulseLoader from 'react-spinners/PulseLoader'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    Typography, 
    Input, 
    Checkbox, 
    Button 
} from "@material-tailwind/react";
import { EyeSlashIcon, EyeIcon } from "@heroicons/react/24/solid";


const CheckMarkIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
    <path fillRule="evenodd" d="M8.603 3.799A4.49 4.49 0 0 1 12 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 0 1 3.498 1.307 4.491 4.491 0 0 1 1.307 3.497A4.49 4.49 0 0 1 21.75 12a4.49 4.49 0 0 1-1.549 3.397 4.491 4.491 0 0 1-1.307 3.497 4.491 4.491 0 0 1-3.497 1.307A4.49 4.49 0 0 1 12 21.75a4.49 4.49 0 0 1-3.397-1.549 4.49 4.49 0 0 1-3.498-1.306 4.491 4.491 0 0 1-1.307-3.498A4.49 4.49 0 0 1 2.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 0 1 1.307-3.497 4.49 4.49 0 0 1 3.497-1.307Zm7.007 6.387a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clipRule="evenodd" />
  </svg>
)



export function SignInPage() {

  const userRef = useRef(null);
  const errRef = useRef();

  const [username, setUsername] = useState("");
  const [passValue, setPassValue] = useState({
    password: "",
    showPassword: false,
  });

  const [passwordValid, setPasswordValid] = useState({
    length: true, // Initially set to true to hide the messages
    noEmail: true
  });

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => setPasswordShown((cur) => !cur);

  const [errMsg, setErrMsg] = useState("");
  const [persist, setPersist] = usePersist();

  const navigate =  useNavigate();
  const dispatch = useDispatch();

  // API Login Mutation
  const [login, { isLoading }] = useLoginMutation()

  const showAlertMessage = (message) => {
    toast.error(message, {
      position: 'top-right',
    });
  };
    
  useEffect(() => {
    userRef.current?.focus()
  }, [userRef])

  useEffect(() => {
      setErrMsg('');
  }, [username, passValue.password])

  const handleSubmit = async (e) => {
      e.preventDefault();
      if (username && passValue.password) {
        // Login from LoginMutation
        try {
          const userData = await login({username, ...passValue}).unwrap();

          dispatch(setCredentials({ ...userData, username }))
          setUsername("");
          setPassValue("");
          navigate('/dashboard/projects')
        } catch (err) {
            if (!err.status) {
                showAlertMessage('No server response');
            } else if (err.status === 400) {
                showAlertMessage('Missing Username or Password');
            } else if (err.status === 401) {
                showAlertMessage('Unauthorized! '+err?.data.message);
            } else if (err.status === 404) {
                showAlertMessage('Not Found');
            } else {
                console.log('error='+JSON.stringify(err));
                let message = err?.data?.message ? 'Login Failed' : 'Could not login, Please call customer support!'
                showAlertMessage(message);
            }
          errRef.current?.focus();
          console.error("ERROR"+JSON.stringify(err));
        }
      }
    }

  const handleUserInput = ({ target }) => setUsername(target.value);

  const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/;

  const checkPasswordValidity = (password) => {
    setPasswordValid({
      length: password.length >= 5,
      //noEmail: !password.includes('@') // Simple check; consider using a more robust email check
      noEmail: !emailRegex.test(password)
    });
  };

  const handlePasswordChange = (prop) => (event) => {
    const newPassword = event.target.value;
    setPassValue({ ...passValue, [prop]: newPassword });
    if (newPassword.length > 0) { // Check if the user has started typing
      checkPasswordValidity(newPassword);
    } else { // Reset the validity state if the password field is empty
      setPasswordValid({
        length: true,
        noEmail: true
      });
    }
  };

  const handleToggle = () => setPersist(prev => !prev);

  const errClass = errMsg ? "errmsg" : "offscreen"

  if (isLoading) return <PulseLoader color="#123abc" size={20} />

  return (
    <div className="min-h-screen flex flex-col">
      <div className="flex-grow overflow-y-auto">
      <section className="bg-gradient-to-r from-xconnections-200 from-10% via-sky-500 via-30% to-emerald-500 to-90% min-h-screen py-8">
        <div className="flex flex-col items-center justify-center px-6 mx-auto lg:py-0">
          <div className="mb-12">
            <Link to="/" className="flex-shrink-0">
              <span className="text-3xl font-extrabold text-blue-600 hover:text-blue-700 transition duration-300 font-custom bg-transparent">
                XConnections
              </span>
            </Link>
          </div>
          <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                Sign in to your account
              </h1>
              <form action="#" className="mx-auto max-w-[24rem] text-left">
                {/* start username section */}        
                <div className="mb-6">
                  <Typography
                    variant="small"
                    className="mb-2 block font-medium text-gray-900"
                  >
                    Your Username
                  </Typography>
                  <Input                
                    id="username"
                    maxLength={16}
                    color="gray"
                    size="lg"
                    type="text"
                    placeholder="username"
                    value={username}
                    onChange={handleUserInput}
                    className="appearance-none !border-t-blue-gray-200 placeholder:text-blue-gray-300 placeholder:opacity-100 focus:!border-t-gray-900 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                  />            
                </div>
                {/* start password section */}        
                <div className="mb-6">
                  <Typography
                    variant="small"
                    className="mb-2 block font-medium text-gray-900"
                  >
                    Password
                  </Typography>
                  <Input
                    id="password"
                    color="gray"
                    size="lg"
                    name="password"
                    type={passwordShown ? "text" : "password"}
                    placeholder="********"
                    className="appearance-none !border-t-blue-gray-200 placeholder:text-blue-gray-300 placeholder:opacity-100 focus:!border-t-gray-900 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}                
                    value={passValue.password}
                    onChange={handlePasswordChange("password")}
                    icon={
                      <i onClick={togglePasswordVisiblity}>
                        {passwordShown ? (
                          <EyeIcon className="h-5 w-5" />
                        ) : (
                          <EyeSlashIcon className="h-5 w-5" />
                        )}
                      </i>
                    }
                  />
                  {(!passwordValid.length || !passwordValid.noEmail) && (
                    <>
                      {!passwordValid.length && (
                        <div className="mb-2 flex w-full flex-col gap-4 md:flex-row">
                          <CheckMarkIcon /> 
                            <Typography className="mt-2 text-xs font-normal text-gray-500">              
                              Must be at least 5 characters
                            </Typography>
                        </div>
                      )}
                      {!passwordValid.noEmail && (
                        <div className="mb-2 flex w-full flex-col gap-4 md:flex-row">
                          <CheckMarkIcon />
                          <Typography className="mt-2 text-xs font-normal text-gray-500">
                            Must not contain an email address
                          </Typography>
                        </div>
                      )}
                    </>
                  )}                  
                  <Checkbox
                    label={
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="flex font-medium items-center !text-gray-500"
                    >
                      Remember me &nbsp;
                    </Typography>
                    }
                    containerProps={{
                      className: "-ml-2.5",
                    }}
                    onChange={handleToggle}
                    checked={persist}
                  />
                </div>
                {/* start submit button */}
                <div className="mb-6"> 
                  <Button
                    type="submit"
                    color={username && passValue.password ? "gray" : "blue-gray"}
                    size="lg" 
                    className="mt-6" 
                    fullWidth
                    disabled={!(username && passValue.password)}
                    onClick={handleSubmit}
                  >
                    log in
                  </Button>
                  <Typography
                    color="gray"
                    className="mt-6 text-center font-normal"
                  >
                    <a href="forgot-password" className="underline font-medium text-gray-900">
                      Forgot password?{" "}
                    </a>
                  </Typography>
                </div>
              </form>
            </div>
          </div>
        </div>
        </section>
    </div>
    </div>
  );
}


export default SignInPage;