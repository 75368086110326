import React from 'react';
import Modal from 'react-modal';

const ClientModal = ({ isOpen, onRequestClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Client Details"
      className="fixed inset-0 flex items-center justify-center z-50"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50"
    >
      <div className="bg-white rounded-lg p-8 w-11/12 max-w-4xl max-h-[90vh] overflow-y-auto">
        <h2 className="text-3xl font-bold mb-6">For Clients</h2>
        <p className="text-gray-700 mb-6 text-lg">
          Access to a network of top-tier vendors with specialized skill sets and independent oversight from project kickoff to delivery.
        </p>
        <div className="mb-6">
          <h3 className="text-xl font-semibold mb-3">Key Benefits:</h3>
          <ul className="list-disc list-inside space-y-2">
            <li>Access to a curated network of expert vendors</li>
            <li>Independent project oversight and management</li>
            <li>Streamlined project kickoff and delivery processes</li>
            <li>Reduced risk and improved project outcomes</li>
          </ul>
        </div>
        <div className="mb-6">
          <h3 className="text-xl font-semibold mb-3">How It Works:</h3>
          <ol className="list-decimal list-inside space-y-2">
            <li>Define your project requirements</li>
            <li>We match you with suitable vendors from our network</li>
            <li>Select your preferred vendor</li>
            <li>We oversee the project from start to finish</li>
            <li>Receive your completed project with quality assurance</li>
          </ol>
        </div>
        <button
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-6 rounded-lg focus:outline-none focus:shadow-outline text-lg"
          onClick={onRequestClose}
        >
          Close
        </button>
      </div>
    </Modal>
  );
};

export default ClientModal;

