import React from 'react';
import { Button } from "@material-tailwind/react";

import { useRegisterUserMutation } from '../../../features/auth/authApiSlice';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const showAlertMessage = (message) => {
    toast.error(message, {
        position: 'top-right',
    });
};

const RegisterUser = ({identity, email, pending}) => {

  const userInfo = {id: identity, email: email, pending: pending}
  
  const [register, { isLoading }] = useRegisterUserMutation()


  const handleRegisterClick = async () => {
    try {
      const response = await register({id: identity, email: email, pending: pending}).unwrap();
      console.log('Register user:', response.data);
    } catch (error) {
      console.error('Failed to register user:', error);
      showAlertMessage("Failed to register user!")
    }
  };

  return (
      <Button onClick={handleRegisterClick} disabled={pending==='N'}>Register</Button>
  );
};

export default RegisterUser;