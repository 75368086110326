import React, { useEffect } from 'react';
import { useGetProjectStepPdfQuery } from "../../features/projects/projectStepsApiSlice";

const PdfDownloader = ({ project_id, filename }) => {
  const [prj, file_name] = filename.split("/");
  const { data, error, isLoading } = useGetProjectStepPdfQuery({ project_id, file_name });

  useEffect(() => {
    if (data && data.blob) {
      const url = window.URL.createObjectURL(data.blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', file_name);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    }
  }, [data, file_name]);

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return <div>Download will start automatically.</div>;
};

export default PdfDownloader;