import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import useAuth from "../../hooks/useAuth";
import { selectCurrentToken } from "../auth/authSlice";


const RequireAuth = ( { allowedRoles }) => {
    const token = useSelector(selectCurrentToken)
    const location = useLocation();
    const { roles } = useAuth();
    // const { auth } = useAuth();
    
    const content = (
        token && roles.some(role => allowedRoles.includes(role))
        // auth?.roles?.find(role => allowedRoles?.includes(role))
            ? <Outlet />
            // : auth?.accessToken // changed from user to accessToken to persist login after refresh
                // ? <Navigate to="/unauthorized" state={{ from: location }} replace />
                : <Navigate to="/login" state={{ from: location }} replace />
    )
    
    return content

}

export default RequireAuth