import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";
import { apiSlice } from "../../api/apiSlice"

const calendarAdapter = createEntityAdapter({
    // sortComparer: (a, b) => (a.completed === b.completed) ? 0 : a.completed ? 1 : -1
})

const initialState = calendarAdapter.getInitialState()

export const calendarApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getCalendar: builder.query({
            query: () => '/calendar/events',
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError
            },
            transformResponse: response => {
                return response
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'Calendar', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'Calendar', id }))
                    ]
                } else return [{ type: 'Calendar', id: 'LIST' }]
            }
        }),
        addNewCalendar: builder.mutation({
            query: initialCalenderEntry => ({
                url: '/calendar/events',
                method: 'POST',
                body: {
                    ...initialCalenderEntry,
                }
            }),
            transformResponse: (response, meta, arg) => {
                return arg;
            },
            invalidatesTags: [
                { type: 'Calendar', id: "LIST" }
            ]
        }),
        updateCalendar: builder.mutation({
            query: initialCalenderEntry => ({
                url: '/calendar/events',
                method: 'PATCH',
                body: {
                    ...initialCalenderEntry,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Calendar', id: arg.id }
            ]
        }),
        deleteCalendar: builder.mutation({
            query: ({ id }) => ({
                url: `/calendar/events/${id}`,
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Calendar', id: arg.id }
            ]
        }),
    }),
})

export const {
    useGetCalendarQuery,
    useAddNewCalendarMutation,
    useUpdateCalendarMutation,
    useDeleteCalendarMutation,
} = calendarApiSlice

// returns the query result object
export const selectCalendarResult = calendarApiSlice.endpoints.getCalendar.select()

// creates memoized selector
const selectCalendarData = createSelector(
    selectCalendarResult,
    calendarResult => calendarResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllcalendar,
} = calendarAdapter.getSelectors(state => selectCalendarData(state) ?? initialState)