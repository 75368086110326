import React, { useState, useEffect } from "react";
import { useCountries } from "use-react-countries";
import { 
  Select, 
  Input, 
  Option,
  Button,
  Typography,
} from "@material-tailwind/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import DefaultLayout from '../../../layouts/DefaultLayout';
import Breadcrumb from '../../../components/Breadcrumb';
import { Link, useNavigate } from 'react-router-dom';
import { useAddNewCompanyMutation } from "../../../features/companies/companyApiSlice"
import DataEntry from '../../../components/svgs/DataEntry';


const AddCompanyForm = () => {

  library.add(fab, fas)

  const [addNewCompany, {
    isLoading,
    isSuccess,
  }] = useAddNewCompanyMutation()

  const navigate = useNavigate();

  const { countries } = useCountries();
  const [country, setCountry] = useState(0);
  const [companyname, setCompanyName] = useState("")
  const [address1, setAddress1] = useState("")
  const [address2, setAddress2] = useState("")
  const [zipcode, setZipCode] = useState("")
  const [state, setState] = useState("")
  const [primaryskill1, setPrimarySkill1] = useState("")
  const [primaryskill2, setPrimarySkill2] = useState("")
  const [primaryskill3, setPrimarySkill3] = useState("")
  const [notes, setNotes] = useState("")
  const [ctype, setCType] = useState("Client");

  const [showError, setShowError] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);


  useEffect(() => {
    if (isSuccess) {
      setCType('')
      setCompanyName('')
      setAddress1('')
      setAddress2('')
      setZipCode('')
      setState('')
      setCountry(0)
      setPrimarySkill1('')
      setPrimarySkill2('')
      setPrimarySkill3('')
      setNotes('')
      navigate("/dashboard/projects")
    }
  }, [isSuccess, navigate])

  const handleCompanyNameChanged = ({ target }) => { setCompanyName(target.value); setShowError(false) };
  const handleAddress1Changed = ({ target }) => setAddress1(target.value);
  const handleAddress2Changed = ({ target }) => setAddress2(target.value);
  const handleZipCodeChanged = ({ target }) => setZipCode(target.value);
  const handleStateChanged = ({ target }) => setState(target.value);
  const handlePrimarySkill1Changed = ({ target }) => setPrimarySkill1(target.value);
  const handlePrimarySkill2Changed = ({ target }) => setPrimarySkill2(target.value);
  const handlePrimarySkill3Changed = ({ target }) => setPrimarySkill3(target.value);
  const handleNotesChanged = ({ target }) => setNotes(target.value);

  const canSave = !isLoading

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!companyname) {
      setShowError(true);
      return;
    }

    // Handle form submission here
    console.log('Company Name:', companyname);
  };

  const onSaveCompanyClicked = async (e) => {
    e.preventDefault()

    setIsSubmitted(true);  // Set this to true when submit is clicked

    if (canSave && companyname) {  // Only proceed if company name is not empty
      let id = 0;
      const returnval = await addNewCompany({ companyname, address1, address2, zipcode, state, country, primaryskill1, primaryskill2, primaryskill3, notes, id, ctype });
    }

    if (!companyname) {
      console.log("Please enter a company name")
      setShowError(true);
      return;
    }

  }


  return (
    <DefaultLayout>
      <Breadcrumb pageName='Add New company' />

      {/* <p className={errClass}>{error?.data?.message}</p> */}
      
      <div className='rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark'>
        <div className='flex flex-wrap items-center'>
          <div className='hidden w-full xl:block xl:w-1/2'>
            <div className='py-17.5 px-26 text-center'>
              <Link className='mb-5.5 inline-block' to='/'>
                <span className="text-2xl font-bold text-blue-600">
                  XConnections
                </span>
              </Link>
              <p className='2xl:px-20'>
                Welcome Admin.  Let's work with a new company.
              </p>
              <DataEntry />
            </div>
          </div>

          {/* Start of Company Registration section */}

          <div className='w-full border-stroke dark:border-strokedark xl:w-1/2 xl:border-l-2'>
            <div className='w-full p-2 sm:p-12.5 xl:p-10'>
              <span className='mb-1.5 block font-medium'>Administration</span>
              <h2 className='mb-9 text-2xl font-bold text-black dark:text-white sm:text-title-xl2'>
                New Company Registration
              </h2>

              <form onSubmit={handleSubmit}
                //className="bg-blue-gray-50 dark:bg-blue-gray-400 shadow-lg rounded-md p-5 md:p-10 flex flex-col w-11/12 max-w-lg"
                novalidate
              >
                {/* Company Name Input */}
                {/*
                <div className="input-container">                    
                  <label 
                    htmlFor="roundedInput" 
                    className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300"
                  >
                    Company Name:
                  </label>
                  <div className="relative">
                    <input 
                      type="text" 
                      id="company_name"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 pr-10 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                      value={companyname}
                      onChange={handleCompanyNameChanged}
                      placeholder="Company Name" 
                      required                  
                    />
                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                      <i className="fas fa-building text-gray-400"></i> 
                    </div>
                  </div>
                  {isSubmitted && !companyname && (
                    <Typography
                      variant="small"
                      color="red"
                      className="mt-2 flex items-center gap-1 font-normal"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="-mt-px h-4 w-4"
                      >
                        <path
                          fillRule="evenodd"
                          d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 01.67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 11-.671-1.34l.041-.022zM12 9a.75.75 0 100-1.5.75.75 0 000 1.5z"
                          clipRule="evenodd"
                        />
                      </svg>
                      Please enter your company name.
                    </Typography>
                  )}
                </div>
                */}
                {/* start company name input */}
                <div className="flex w-full flex-col gap-1">
                  <label className='mb-2.5 block font-medium text-black dark:text-white'>
                    Company Name
                  </label>
                  <Input 
                    label="company name"
                    type="text"
                    icon={<i className="fa-solid fa-building"></i>}
                    value={companyname}
                    onChange={handleCompanyNameChanged}
                    placeholder='Enter company name'
                    error={showError ? true : false}
                    required
                  />
                  {isSubmitted && !companyname && (
                    <Typography
                      variant="small"
                      color="red"
                      className="mt-2 flex items-center gap-1 font-normal"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="-mt-px h-4 w-4"
                      >
                        <path
                          fillRule="evenodd"
                          d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 01.67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 11-.671-1.34l.041-.022zM12 9a.75.75 0 100-1.5.75.75 0 000 1.5z"
                          clipRule="evenodd"
                        />
                      </svg>
                      Please enter your company name.
                    </Typography>
                  )}                  
                </div>
                {/* end company name input */}
                <div className='mb-4'>
                </div>              
                {/* start address1 input */}
                <div className="flex w-full flex-col gap-1">
                  <label className='mb-2.5 block font-medium text-black dark:text-white'>
                    Address 1
                  </label>
                  <Input 
                    label="primary address"
                    type="text"
                    icon=<FontAwesomeIcon icon="fa-solid fa-house" />
                    value={address1}
                    onChange={handleAddress1Changed}
                    placeholder='Enter your Address'
                  />
                </div>
                {/* end address1 name input */}
                <div className='mb-4'>
                </div>                  
                {/* start address2 input */}
                <div className="flex w-full flex-col gap-1">
                  <label className='mb-2.5 block font-medium text-black dark:text-white'>
                    Address 2
                  </label>
                  <Input 
                    label="secondary address"
                    type="text"
                    icon=<FontAwesomeIcon icon="fa-solid fa-house" />
                    value={address2}
                    onChange={handleAddress2Changed}
                    placeholder='Enter secondary Address'
                  />
                </div>
                {/* end address2 name input */}
                <div className='mb-4'>
                </div>  
                {/* start zipcode input */}
                <div className="flex w-full flex-col gap-1">
                  <label className='mb-2.5 block font-medium text-black dark:text-white'>
                    Zip Code
                  </label>
                  <Input 
                    label="zipcode"
                    type="text"
                    icon=<FontAwesomeIcon icon="fa-solid fa-map" />
                    value={zipcode}
                    onChange={handleZipCodeChanged}
                    placeholder='Enter your Zipcode'
                  />
                </div>
                {/* end zipcode input */}
                <div className='mb-4'>
                </div> 
                {/* start state input */}
                <div className="flex w-full flex-col gap-1">
                  <label className='mb-2.5 block font-medium text-black dark:text-white'>
                    State
                  </label>
                  <Input 
                    label="state"
                    type="text"
                    icon=<FontAwesomeIcon icon="fa-solid fa-flag" />
                    value={state}
                    onChange={handleStateChanged}
                    placeholder='New York'
                  />
                </div>
                {/* end state input */}
                <div className='mb-4'>
                </div> 
                {/* start country selection */}
                <div className="w-72">
                  <label className='mb-2.5 block font-medium text-black dark:text-white'>
                    Country
                  </label>                
                  <Select className='mb-2.5 block font-medium text-black dark:text-white'
                    size="lg"
                    label="Select Country"
                    selected={(element) =>
                      element &&
                      React.cloneElement(element, {
                        disabled: true,
                        className:
                          "flex items-center opacity-100 px-0 gap-2 pointer-events-none",
                      })
                    }
                  >
                    {countries.map(({ name, flags }) => (
                      <Option 
                        className="flex items-center gap-2"
                        key={name} 
                        value={name}
                        defaultValue="US"
                        onClick={() => setCountry(name)}
                      >
                        <img
                          src={flags.svg}
                          alt={name}
                          className="h-5 w-5 rounded-full object-cover"                          
                        />
                        {name}
                      </Option>
                    ))}
                  </Select>
                </div>
                {/* end country selection */}
                {/* end primary skill1 input */}
                <div className='mb-4'>
                </div>
                {/* start primary skill1 input */}
                <div className="flex w-full flex-col gap-1">
                  <label className='mb-2.5 block font-medium text-black dark:text-white'>
                    Primary Skill 1
                  </label>
                  <Input 
                    label="primary skill"
                    type="text"
                    icon=<FontAwesomeIcon icon="fa-solid fa-cog" />
                    value={primaryskill1}
                    onChange={handlePrimarySkill1Changed}
                    placeholder='Enter your Primary Skill'
                  />
                </div>
                {/* end primary skill1 input */}
                <div className='mb-4'>
                </div> 
                {/* start primary skill2 input */}
                <div className="flex w-full flex-col gap-1">
                  <label className='mb-2.5 block font-medium text-black dark:text-white'>
                    Primary Skill 2
                  </label>
                  <Input 
                    label="secondary skill"
                    type="text"
                    icon=<FontAwesomeIcon icon="fa-solid fa-cog" />
                    value={primaryskill2}
                    onChange={handlePrimarySkill2Changed}
                    placeholder='Enter your Secondary Skill'
                  />
                </div>
                {/* end primary skill2 input */}
                <div className='mb-4'>
                </div> 
                {/* start primary skill3 input */}
                <div className="flex w-full flex-col gap-1">
                  <label className='mb-2.5 block font-medium text-black dark:text-white'>
                    Primary Skill 3
                  </label>
                  <Input 
                    label="tertiary skill"
                    type="text"
                    icon=<FontAwesomeIcon icon="fa-solid fa-cog" />
                    value={primaryskill3}
                    onChange={handlePrimarySkill3Changed}
                    placeholder='Enter your Tertiary Skill'
                  />
                </div>
                {/* end primary skill3 input */}
                <div className='mb-4'>
                </div> 
                {/* start notes input */}
                <div className="flex w-full flex-col gap-1">
                  <label className='mb-2.5 block font-medium text-black dark:text-white'>
                    Notes
                  </label>
                  <Input 
                    label="notes"
                    type="text"
                    icon=<FontAwesomeIcon icon="fa-solid fa-book" />
                    value={notes}
                    onChange={handleNotesChanged}
                    placeholder='Notes'
                  />
                </div>
                {/* end notes input */}
                <div className='mb-4'>
                </div>

                {/* start user type selection */}
                <div className="flex w-full flex-col gap-1">
                  <label className='mb-2.5 block font-medium text-black dark:text-white'>
                    User Type
                  </label>
                  <Select
                    variant="outlined" 
                    color="blue"
                    label="User Type"
                    value={ctype}
                    onChange={(val) => setCType(val)}
                  >
                    <Option value="client">Client</Option>
                    <Option value="vendor">Vendor</Option>
                  </Select>
                </div>
                <div className='mb-4'>
                </div>
                {/* end user type selection */}

                <div className='mb-5'>
                  <Button
                    variant="gradient"
                    className='w-full cursor-pointer rounded-lg border border-primary bg-primary p-4 text-white transition hover:bg-opacity-90'
                    onClick={onSaveCompanyClicked}
                  >
                    Add New Company
                  </Button>
                </div>                
              </form>
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  )
};

export default AddCompanyForm;