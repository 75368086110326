import React, { useState } from 'react';
import ClientModal from '../modals/client-modal';
import VendorModal from '../modals/vendor-modal';
import EveryoneModal from '../modals/everyone-modal';
import { Typography } from '@material-tailwind/react'

import Clients from '../../assets/images/teams/clients.jpeg'
import Vendors from '../../assets/images/teams/vendors.jpeg'
import Everyone from '../../assets/images/teams/everyone.jpeg'


export function GridCard({ color, icon, title, description }) {

  const [isClientModalOpen, setIsClientModalOpen] = useState(false);
  const [isVendorModalOpen, setIsVendorModalOpen] = useState(false);
  const [isEveryoneModalOpen, setIsEveryoneModalOpen] = useState(false);

  const openClientModal = () => {
    setIsClientModalOpen(true);
  };

  const closeClientModal = () => {
    setIsClientModalOpen(false);
  };

  const openVendorModal = () => {
    setIsVendorModalOpen(true);
  };

  const closeVendorModal = () => {
    setIsVendorModalOpen(false);
  };

  const openEveryoneModal = () => {
    setIsEveryoneModalOpen(true);
  };

  const closeEveryoneModal = () => {
    setIsEveryoneModalOpen(false);
  };

  return (
    <section className="bg-gradient-to-r from-blue-700 from-0% via-blue-500 via-60% to-blue-300 to-100%">
      {/* start screen */}
      <div className="max-w-screen-xl mx-auto p-5 sm:p-10 md:p-16">
        {/* start border */}
        <div className="border-b mb-5 flex justify-between text-sm">
          <div className="text-black-2 flex items-center pb-2 pr-2 border-b-2 border-black uppercase">
            <a href="/aboutus" target="_blank" className="font-semibold inline-block">About Us</a>
          </div>
        </div>
        {/* end border */}
        {/* start group */}
        <div className="group">
          {/* start grid columns */}
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-10">

            {/* For Clients Card */}
            <div 
              className="bg-white rounded-lg overflow-hidden shadow-lg flex flex-col hover:scale-105 hover:shadow-xl transition-all duration-300 cursor-pointer h-full"
              onClick={openClientModal}
            >
              <div className="relative h-48">
                <img 
                  className="w-full h-full object-cover" 
                  src={Clients} 
                  alt="Clients"
                />
                <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-50"></div>
                <h3 className="absolute bottom-4 left-4 text-white text-2xl font-bold">For Clients</h3>
              </div>
              <div className="p-4 flex-grow flex flex-col justify-between">
                <p className="text-gray-700 text-base mb-4">
                  Access to a network of top-tier vendors with specialized skill sets and independent oversight from project kickoff to delivery.
                </p>
                <div className="flex items-center text-blue-500 hover:text-blue-700 transition-colors duration-300">
                  <span className="mr-2">Learn More </span>
                  <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path>
                  </svg>
                </div>
              </div>
            </div>

            {/* For Vendors Card */}
            <div 
              className="bg-white rounded-lg overflow-hidden shadow-lg flex flex-col hover:scale-105 hover:shadow-xl transition-all duration-300 cursor-pointer h-full"
              onClick={openVendorModal}
            >
              <div className="relative h-48">
                <img 
                  className="w-full h-full object-cover" 
                  src={Vendors} 
                  alt="Vendors"
                />
                <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-50"></div>
                <h3 className="absolute bottom-4 left-4 text-white text-2xl font-bold">For Vendors</h3>
              </div>
              <div className="p-4 flex-grow flex flex-col justify-between">
                <p className="text-gray-700 text-base mb-4">
                  Access to a network of clients to promote services offered and credibility to attract more customers.
                </p>
                <div className="flex items-center text-blue-500 hover:text-blue-700 transition-colors duration-300">
                  <span className="mr-2">Learn More </span>
                  <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path>
                  </svg>
                </div>
              </div>
            </div>

            {/* For Everyone Card */}
            <div 
              className="bg-white rounded-lg overflow-hidden shadow-lg flex flex-col hover:scale-105 hover:shadow-xl transition-all duration-300 cursor-pointer h-full"
              onClick={openEveryoneModal}
            >
              <div className="relative h-48">
                <img 
                  className="w-full h-full object-cover" 
                  src={Everyone} 
                  alt="Everyone"
                />
                <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-50"></div>
                <h3 className="absolute bottom-4 left-4 text-white text-2xl font-bold">For Everyone</h3>
              </div>
              <div className="p-4 flex-grow flex flex-col justify-between">
                <p className="text-gray-700 text-base mb-4">
                  Trusted partner with vast experience to provide guidance and support throughout the product lifecycle and offer visibility.
                </p>
                <div className="flex items-center text-blue-500 hover:text-blue-700 transition-colors duration-300">
                  <span className="mr-2">Learn More </span>
                  <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path>
                  </svg>
                </div>
              </div>
            </div>
            {/* <!-- end Everyone Card --> */}                    
          </div>
          {/* end grid */}
        </div>
        {/* end group */}
      </div>
      {/* end screen */}
      <ClientModal isOpen={isClientModalOpen} onRequestClose={closeClientModal} />
      <VendorModal isOpen={isVendorModalOpen} onRequestClose={closeVendorModal} />
      <EveryoneModal isOpen={isEveryoneModalOpen} onRequestClose={closeEveryoneModal} />
    </section>
    );
}

GridCard.displayName = "/src/widgets/layout/grid-card.jsx";

export default GridCard;