import { createEntityAdapter } from "@reduxjs/toolkit"
import { apiSlice } from "../../api/apiSlice"


const usersProfileAdapter = createEntityAdapter({})
const initialState = usersProfileAdapter.getInitialState()

export const userProfileSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserProfile: builder.query({
            query: () => ({
                url: '/user/profile',
                header: {'content-type': 'application/json', 
                    'Access-Control-Allow-Origin': '*', 
                    'Access-Control-Allow-Credentials': "true"
                },
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            }),
            transformResponse: response => {
                    return response
                },
        }),
        updateUserProfile : builder.mutation({
            query : userProfile => ({
                url: '/user/updateprofile',
                method: 'PATCH',
                body: {
                    ...userProfile,
                }
            })
        }),
        updateProfileImg : builder.mutation({
            query: (img_file) => ({
                url: '/user/updateprofileimg',
                method: 'POST',
                body: img_file,
            }),
            transformResponse: response => {
                 return response;
            },
        }),

    })
});

export const {
    useGetUserProfileQuery,
    useUpdateUserProfileMutation,
    useUpdateProfileImgMutation,
} = userProfileSlice