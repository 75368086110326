import {
    createSelector,
    createSlice,
    createAsyncThunk,
    current,
    isRejectedWithValue,
    nanoid,
    original,
    createEntityAdapter
} from "@reduxjs/toolkit";
import { apiSlice } from "../../api/apiSlice"

import axios from "axios";

// create a thunk action creator using createAsyncThunk in order to manage asynchronous request lifecyles
// this will be accessible within components & hooks to be dispatched, in order to fire off a request for
// some user data.
// createAsyncThunk itself will handle dispatching lifecycle methods for our request: pending, fulfilled,
// and rejected, which we will handle within our slice.
export const fetchRfpById = createAsyncThunk("rfps/fetchRfps", async (id, {rejectWithValue}) => {
    const response = await axios.get(`/client/rfp/${id}`);
    const data = await response.json()
    if (response.status < 200 || response.status >= 300) {
        return rejectWithValue(data)
    }
    return data
});

const rfpAdapter = createEntityAdapter({
    // assume IDs are stored in a field other than `rfp.id`
    // TODO: should this be => id
    selectId: (rfp) => rfp.id,
    // keep the "all IDs" array sorted based on rfp name
    // TODO: should this be a.status AND b.status
    sortComparer: (a, b) => a.status.localeCompare(b.status)
})

const initialState = rfpAdapter.getInitialState({
    //status: 'idle',
    status: 'new-rfp',
    error: null,
})

// TODO: this should be the query return of rfp_requests
const rfpAppState = {
    rfps: [
        { id: 0, description: 'Build AI Platform', duration: 2, budget: 15000.0, preferredTechnology: 'React', existingProject: 'no', status: 'new-rfp', domina: 'IT'},
        { id: 1, description: 'Build Web Platform', duration: 5, budget: 25000.0, preferredTechnology: 'Python', existingProject: 'no', status: 'requirement-analysis', domain: 'HealthCare'},
        { id: 2, description: 'Build Finance Application', duration: 12, budget: 125000.0, preferredTechnology: 'React', existingProject: 'no', status: 'requirement-analysis', domain: 'Finance'},
        { id: 3, description: 'Build Gaming Platform', duration: 15, budget: 225000.0, preferredTechnology: 'C++', existingProject: 'no', status: 'vendor-notification', domain: 'Gaming'},
    ],
    filters: {
        status: 'All',
    }
}

const rfpSlice = createSlice({
    name: 'rfp', // name of the slice
    //initialState: rfpAdapter.getInitialState({
    //    starting: 'new-rfp', // initial state for the slice
    //}),
    initialState: initialState,
    reducers: {
        // Can pass adapter functions directly as case reducers.  Because we're passing this
        // as a value, `createSlice` will auto-generate the `rfpAdded` action type / creator
        rfpAdded: (state, action) => {
            console.log("rfpAdded was called...")
            console.log("rfpAdded state", state)
            console.log("rfpAdded action", action)
            // This reducer adds a single entity to the state
            // and an action payload containing a single entity, and adds that entity to the state
            rfpAdapter.addOne(state, action.payload)
        },
        //rfpAdded: {
        //    reducer: rfpAdapter.addOne,
        //    prepare: (rfp) => ({ payload: { ...rfp, id: nanoid() } }),
        //},
        //rfpUpdated: {
        //    reducer: rfpAdapter.updateOne,
        //    prepare: (id, changes) => ({ payload: { id, changes } })
        //},
        rfpUpdated: {
            // this reducer updates a single entity in the state
            // it takes the current state and an action payload containing an update object (with id and changes)
            // and applies those changes to the specified entity in the state
            reducer: rfpAdapter.updateOne,
            prepare: (rfp, changes) => ({
                payload: { id: rfp.id, changes, oldColumn: rfp.column },
            }),
        },
        rfpDeleted: rfpAdapter.removeOne,
        rfpReset: rfpAdapter.removeAll,
        // rfpReceived: rfpAdapter.setAll
        rfpChangeStatus(state, action) {
            // Can update the additional state field
            console.log('+++++++++++++++++++++++++++++ rfpChangeStatus', state)
            console.log('+++++++++++++++++++++++++++++ rfpChangeStatus', action.payload) //{id: 4, status: 'vendor-notification'}
            //state.value = action.payload
            return {
                ...state,
                value: action.payload
            };
        },
        updateRfpStatus(state, action) {
            const { id, status } = action.payload;
            console.log('Updating RFP status:', { id, status, currentState: state }); //{id:6, status: 'requirement-analysis'}
            rfpAdapter.updateOne(state, { id, changes: { status } });
            console.log('Updated state:', state);
        },
        /*
        updateRfpStatus(state, action) {
            console.log('+++++++++++++++++++++++++++++ updateRfpStatus', state)
            console.log('+++++++++++++++++++++++++++++ updateRfpStatus', action.payload) //{id: 4, status: 'vendor-notification'}
            //rfps: state.rfps.map(rfp => rfp.id === action.payload.id ? {...rfp, status: action.payload.status} : rfp)
            return {
                ...state,
                rfps: state.rfps.map(rfp => {
                    if (rfp.id === action.payload.id) {
                        return {...rfp, status: action.payload.status}
                    }
                    return {
                        ...rfp,
                        status: rfp.status
                    };
                })
            }
        },
        */
        rfpStarting(state, action) {
            if (state.starting === 'new-rfp') {
                state.starting = 'pending'
            }
        },
        rfpReceived(state, action) {
            // Or, call them as "mutating" helpers in a case reducer
            // rfpAdapter.removeAll(state)
            // rfpAdapter.setAll(state, action.payload)
            if (state.starting === 'pending') {
                rfpAdapter.setAll(state, action.payload.rfps)
                state.starting = 'succeeded'
            }
        },
        rfpRequest(state, action) {
            // Can update the additional state field
            state.starting = 'pending'
        },
        rfpSuccess(state, action) {
            state.starting = 'succeeded'
        },
        rfpFailure(state, action) {
            state.starting = 'failed'
        }
    },
    extraReducers: (builder) => {
        // When our request is pending:
        // - store the 'pending' state as the status for the corresponding user name
        builder.addCase(fetchRfpById.pending, (state, action) => {
        state.statusByName[action.meta.arg] = 'pending'
        })
        // When our request is fulfilled:
        // - store the 'fulfilled' state as the status for the corresponding user name
        // - and store the received payload as the data for the corresponding user name
        builder.addCase(fetchRfpById.fulfilled, (state, action) => {
        state.statusByName[action.meta.arg] = 'fulfilled'
        state.dataByName[action.meta.arg] = action.payload
        })
        // When our request is rejected:
        // - store the 'rejected' state as the status for the corresponding user name
        builder.addCase(fetchRfpById.rejected, (state, action) => {
        state.statusByName[action.meta.arg] = 'rejected'
        })
    },    
})

//export default rfpSlice
// Export the reducer function from the slice
/*
export const {
    rfpAdded,
    rfpUpdated,
    rfpDeleted,
    rfpReset,
    rfpChangeStatus,
    rfpStarting,
    rfpReceived,
    rfpRequest,
    rfpSuccess,
    rfpFailure
} = rfpSlice.actions
*/
//export default rfpSlice.reducer

//export const { actions: rfpActions, reducer: rfpReducer} = rfpActions;
//export const { selectors: rfpSelectors } = rfpAdapter.getSelectors((state) => state.rfp)
//export const rfpApiSlice.reducer

// Export the actions and reducer
export const rfpActions = rfpSlice.actions;
export const rfpReducer = rfpSlice.reducer;

// Export the selectors
export const rfpSelectors = rfpAdapter.getSelectors((state) => state.rfp);