import React, {useState, useEffect} from "react";
import {
  Button,
  Dialog,
  Card,
  CardBody,
  CardFooter,
  Typography,
  Input,
} from "@material-tailwind/react";
import { useUpdateUserMutation, useDeleteUserMutation } from "../../../features/users/usersApiSlice";
import { useNavigate, useParams } from "react-router-dom"

export function EditUserForm({identity, uname, email, phone, user_type, sector, pending, fname, lname}) {

  const { id } = useParams()

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen((cur) => !cur);

  const [updateUser, // This is the mutation trigger
  { isLoading: isUpdating, isSuccess, isError, error } // This is the destructured mutation result
  ] = useUpdateUserMutation()


  const [deleteUser, {
    isSuccess: isDelSuccess,
    isError: isDelError,
    error: delerror
  }] = useDeleteUserMutation()

  const [userFirstName, setUserFirstName] = useState(fname)
  const [userLastName, setUserLastName] = useState(lname)
  const [userEmail, setUserEmail] = useState(email)
  const [userPending, setUserPending] = useState(pending)

  const navigate = useNavigate()

  useEffect(() => {

    if (isSuccess || isDelSuccess) {
        setUserFirstName('')
        setUserLastName('')
        setUserEmail('')
        setUserPending('')
        navigate('/auth/viewusers')
    }

  }, [isSuccess, isDelSuccess, navigate])

  const onUserFirstNameChanged = e => setUserFirstName(e.target.value)
  const onUserLastNameChanged = e => setUserLastName(e.target.value)
  const onUserEmailChanged = e => setUserEmail(e.target.value)
  const onUserPendingChanged = e => setUserPending(prev => !prev)

  const canSave = !isUpdating

  const onSaveUserClicked = async (e) => {
    if (canSave) {
        await updateUser({ uname: uname, fname: userFirstName, lname: userLastName, email: userEmail, pending: userPending })
    }
  }

  const onDeleteUserClicked = async () => {
    await deleteUser({ id: identity })
  }

  const errClass = (isError || isDelError) ? "errmsg" : "offscreen"

  const errContent = (error?.data?.message || delerror?.data?.message) ?? ''

  const content = (
    <>
      <p className={errClass}>{errContent}</p>

      <Button onClick={handleOpen}>Edit User</Button>
      <Dialog
        size="lg"
        open={open}
        handler={handleOpen}
        className="mt-12 flex flex-col gap-4"
      >
        <Card className="mx-auto w-full max-w-[48rem]">
          <CardBody className="flex flex-col gap-4">
            <Typography variant="h4" color="blue-gray">
              Update User Information
            </Typography>
            <Typography
              className="mb-3 font-normal"
              variant="paragraph"
              color="gray"
            >
              Please update the necessary fields
            </Typography>
            <div className="my-4 flex items-center gap-4">
              <div>
                <Typography className="-mb-2" variant="h6">
                  First Name
                </Typography>
                <Input size="md" defaultValue={fname} onChange={onUserFirstNameChanged}/>
              </div>
              <div>
                <Typography className="-mb-2" variant="h6">
                  Last Name
                </Typography>
                <Input size="md" defaultValue={lname} onChange={onUserLastNameChanged}/>
              </div>
            </div>
            <div className="my-4 flex items-center gap-4">
            <div>
              <Typography className="-mb-2" variant="h6">
                Email
              </Typography>
              <Input size="lg" defaultValue={email} onChange={onUserEmailChanged}/>
            </div>
            <div>
              <Typography className="-mb-2" variant="h6">
                Registered
              </Typography>
              <Input size="lg" defaultValue={pending} onChange={onUserPendingChanged} />
            </div>
            </div>
          </CardBody>
          <CardFooter className="pt-0">
            <div className="flex items-center gap-4">
            <Button variant="gradient" className="flex items-center gap-3" onClick={onSaveUserClicked}>
              Update User
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                className="h-5 w-5"
              >
              <path d="M410.3 231l11.3-11.3-33.9-33.9-62.1-62.1L291.7 89.8l-11.3 11.3-22.6 22.6L58.6 322.9c-10.4 10.4-18 23.3-22.2 37.4L1 480.7c-2.5 8.4-.2 17.5 6.1 23.7s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L387.7 253.7 410.3 231zM160 399.4l-9.1 22.7c-4 3.1-8.5 5.4-13.3 6.9L59.4 452l23-78.1c1.4-4.9 3.8-9.4 6.9-13.3l22.7-9.1v32c0 8.8 7.2 16 16 16h32zM362.7 18.7L348.3 33.2 325.7 55.8 314.3 67.1l33.9 33.9 62.1 62.1 33.9 33.9 11.3-11.3 22.6-22.6 14.5-14.5c25-25 25-65.5 0-90.5L453.3 18.7c-25-25-65.5-25-90.5 0zm-47.4 168l-144 144c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l144-144c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z"></path>
              </svg>
            </Button>
            <Button variant="outlined" className="flex items-center gap-3" onClick={onDeleteUserClicked}>
              Delete User
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                className="h-5 w-5"
              >
              <path d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z"></path>
              </svg>
            </Button>
            </div>
          </CardFooter>
        </Card>
      </Dialog>
    </>
  )

  return content
}