import { store } from '../../api/store'
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { usersApiSlice } from '../../features/users/usersApiSlice'

const Prefetch = () => {
    useEffect(() => {
        console.log('subscribing')
        //const users = store.dispatch(usersApiSlice.endpoints.getUsers.initiate())
        //store.dispatch(usersApiSlice.util.prefetch('getUsers', 'usersTable', { force: true }))

        return () => {
            console.log('unsubscribing')
            //users.unsubscribe()
        }
    }, [])

    return <Outlet />
}
export default Prefetch