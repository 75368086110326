import React, { useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import {
    Input,
    Button,
    Typography,
} from "@material-tailwind/react";
import { useForgotPasswordUserMutation } from "../../features/auth/authApiSlice";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


const showAlertMessage = (message) => {
    toast.error(message, {
        position: 'top-right',
    });
};

const showConfirmationMessage = (message) => {
    toast.success(message, {
        position: 'top-left',
    });
};

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const [forgotPasswordUser] = useForgotPasswordUserMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await forgotPasswordUser({email: email}).unwrap();
      console.log('Password Reset user:', response.message);
      showConfirmationMessage("Password reset link has been sent!")
      // TODO: after submission, bring user to page that shows:
      // An email has been sent to <email> with a link that will allow you to 
      // reset your password.
      navigate('/login')
    } catch (error) {
      console.error("error=", JSON.stringify(error));
      console.error('Failed to activate user: ', error?.data.message);
      // NOTE: transformErroResponse only returns status
      // console.error("Failed to activate user: ", error)
      showAlertMessage("You are not registered!")
    }
  }

  const handleEmailInput = ({ target }) => setEmail(target.value);


  return (
    <div className="flex flex-col min-h-screen">
      {/* Navbar */}
      <nav className="bg-white shadow-md">
        <div className="container mx-auto px-4 py-4">
          <Link to="/" className="flex-shrink-0">
            <span className="text-3xl font-extrabold text-blue-600 hover:text-blue-700 transition duration-300">
              XConnections
            </span>
          </Link>
        </div>
      </nav>

      {/* Main Content */}
      <div className="flex-grow flex items-center justify-center px-4 py-12">
        <div className="w-full max-w-md">
          <Typography variant="h4" color="blue-gray" className="text-center mb-6">
            Forgot Password
          </Typography>
          <Typography variant="paragraph" color="blue-gray" className="text-center mb-8">
            Enter your email address associated with your account 
            and we will send you a link to reset your password.
          </Typography>
          <form className="flex flex-col gap-6">
            <div>
              <Typography variant="small" color="blue-gray" className="mb-2 font-medium">
                Email
              </Typography>
              <Input
                size="lg"
                type="email"
                placeholder="name@email.com"
                value={email}
                onChange={handleEmailInput}
                className="!border-t-blue-gray-200 focus:!border-t-blue-600"
              />
            </div>
            <Button 
              fullWidth
              color={email ? "blue" : "blue-gray"}
              disabled={!email}
              onClick={handleSubmit}
            >            
              Continue
            </Button>
          </form>
          <Typography variant="paragraph" className="text-center text-blue-gray-500 font-medium mt-6">
            Already a trusted partner?
            <Link to="/login" className="text-blue-600 ml-1 hover:text-blue-700">Login</Link>
          </Typography>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
