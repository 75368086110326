import React from 'react';
import DefaultLayout from '../layouts/DefaultLayout'
import Breadcrumb from '../components/Breadcrumb';
import {Calendar, momentLocalizer} from 'react-big-calendar';
import moment from 'moment';
import "react-big-calendar/lib/css/react-big-calendar.css";

import { useGetCalendarQuery } from '../features/calendar/calendarApiSlice';


const localizer = momentLocalizer(moment);

const ProjCalendar = () => {
  const {
    data: calendar_details,
    isLoading,
    isSuccess,
    error
  } = useGetCalendarQuery('calendarTable', {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true
  })
  
  const myevents = calendar_details?.map((item) =>({
    id: item.id,
    title: item.Subject,
    start: new Date(item.StartTime),
    end: new Date(item.EndTime),
    desc: item.text
  }));

  return (
    <DefaultLayout>
      <Breadcrumb pageName='Calendar' />
      <div>
      <Calendar
      localizer={localizer}
      startAccesor="start"
      endAccessor="end"
      style={{height: 600}}
      events={myevents}
      />
      </div>

    </DefaultLayout>
  )
}

export default ProjCalendar;