import React, {useState } from "react";
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import {
    Input,
    Checkbox,
    Button,
    Typography,
} from "@material-tailwind/react";
import { Link } from "react-router-dom";
import ActivationPattern from "../../assets/images/patterns/activation_pattern.avif"
import { 
    useActivateUserMutation, 
    useGetActivationLinkQuery 
} from "../../features/auth/authApiSlice";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Alert } from "@material-tailwind/react";


const showAlertMessage = (message) => {
    toast.error(message, {
        position: 'top-right',
    });
};

const showConfirmationMessage = (message) => {
    toast.success(message, {
        position: 'top-left',
    });
};


export function ActivationPage() {

    const [email, setEmail] = useState("");
    const [terms, setTerms] = useState(0);
    const [uname, setUserName] = useState("");
    const [activationCode, setActivationCode] = useState("");
    const [user_pwd, setPassword] = useState("");
    const [confirm_pwd, setCPassword] = useState("");

    const navigate = useNavigate();

    const params = useParams();

    const userHash = params.hashId;

    const [activateUser, { token, userEmail , username}] = useActivateUserMutation();

    const { data: message, isSuccess, error }  = useGetActivationLinkQuery(userHash);

    if (isSuccess) {
        console.log('Activation Link: ', message)
    } else {
        console.error("Check Activation Link error=", JSON.stringify(error));
        return (<h2>Link has expired!</h2>)
    }

    const handleActivation = async (e) => {
        e.preventDefault();
        if(user_pwd !== confirm_pwd){
            <Alert variant="warning" dismissible>
            <p className="mb-0">
            "Please ensure that the passwords match! "
            </p>
          </Alert>
        }
        else{
            try {
                  const response = await activateUser({hashId: userHash, email:email, username:uname, password: user_pwd}).unwrap();
                  console.log('Activated user:', response.message);
                  showConfirmationMessage("Activation has been confirmed!")
                  navigate('/login')
                } catch (error) {
                  console.error("error=", JSON.stringify(error));
                  console.error('Failed to activate user: ', error?.data.message);
                  // NOTE: transformErroResponse only returns status
                  // console.error("Failed to activate user: ", error)
                  showAlertMessage("Failed to activate user!")
                }
        }
    }
  
    const handleEmailInput = ({ target }) => setEmail(target.value);
    const handleClickTerms = ({ target }) => setTerms(target.value);
    const handleUserNameChange = ({ target }) => setUserName(target.value);
    const handleActivationCode = () => setActivationCode(userHash)
    const handlePasswordChange = ({ target}) => setPassword(target.value);
    const handleCPasswordChange = ({ target}) => setCPassword(target.value);

    // TODO: change to if (error)
    if (message === 'Activation link has expired!') {
        // TODO: add a better looking page for activation link expired
        return (<h2>Link has expired!</h2>)
    } else {    
        return  (
            <div className="relative flex h-screen content-center items-center justify-center pt-16 pb-32">
            <section className="m-8 flex">
            <div className="w-2/5 h-full hidden lg:block">
                <img
                alt="pattern"
                src={ActivationPattern}
                className="h-full w-full object-cover rounded-3xl"
                />
            </div>
            <div className="w-full lg:w-3/5 flex flex-col items-center justify-center">
                <div className="text-center">
                    <Typography variant="h2" className="font-bold mb-4">Complete your sign-up</Typography>
                    <Typography variant="paragraph" color="blue-gray" className="text-lg font-normal">Enter your activation code.</Typography>
                </div>
                <form className="mt-8 mb-2 mx-auto w-80 max-w-screen-lg lg:w-1/2">
                <div className="mb-1 flex flex-col gap-6">
                    <Typography variant="small" color="blue-gray" className="-mb-3 font-medium">
                        Activation Code
                    </Typography>
                    <Input
                        size="lg"
                        type="text"
                        placeholder="activation code"
                        value={userHash}
                        onChange={handleActivationCode}
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                            className: "before:content-none after:content-none",
                        }}
                    />
                    <Typography variant="small" color="blue-gray" className="-mb-3 font-medium">
                        Confirm email
                    </Typography>
                    <Input
                        size="lg"
                        type="text"
                        placeholder="name@email.com"
                        value={email}
                        onChange={handleEmailInput}
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                            className: "before:content-none after:content-none",
                        }}
                    />
                    <Typography variant="small" color="blue-gray" className="-mb-3 font-medium">
                    Choose a username
                </Typography>
                <Input
                    size="lg"
                    type="text"
                    placeholder=""
                    value={uname}
                    onChange={handleUserNameChange}
                    className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                    labelProps={{
                        className: "before:content-none after:content-none",
                    }}
                />
                <Typography variant="small" color="blue-gray" className="-mb-3 font-medium">
                    New Password:
                </Typography>
                <Input
                    size="lg"
                    type="password"
                    placeholder="********"
                    value={user_pwd}
                    onChange={handlePasswordChange}
                    className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                    labelProps={{
                        className: "before:content-none after:content-none",
                    }}
                />
                <Typography variant="small" color="blue-gray" className="-mb-3 font-medium">
                    Confirm Password:
                </Typography>
                <Input
                    size="lg"
                    type="password"
                    placeholder="********"
                    value={confirm_pwd}
                    onChange={handleCPasswordChange}
                    className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                    labelProps={{
                        className: "before:content-none after:content-none",
                    }}
                />
            </div>
                <Checkbox
                    value={terms}
                    onClick={handleClickTerms}
                    label={
                    <Typography
                        variant="small"
                        color="gray"
                        className="flex items-center justify-start font-medium"
                    >
                        I agree the&nbsp;
                        <a
                            href="/terms"
                            className="font-normal text-black transition-colors hover:text-gray-900 underline"
                        >
                            Terms and Conditions
                        </a>
                    </Typography>
                    }
                    containerProps={{ className: "-ml-2.5" }}
                />
                <Button 
                    className="mt-6" 
                    fullWidth
                    color={email && terms ? "gray" : "blue-gray"}
                    disabled={!(email && terms)}
                    //onClick={handleSignUp}
                    onClick={handleActivation}
                >            
                    Submit
                </Button>
                <Typography variant="paragraph" className="text-center text-blue-gray-500 font-medium mt-4">
                    Already a trusted partner?
                    <Link to="/login" className="text-gray-900 ml-1">Login</Link>
                </Typography>
                </form>
            </div>
            </section>
            </div>
    );
}
}

export default ActivationPage;