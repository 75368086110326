import React, {useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router';
import { useParams, useSearchParams } from 'react-router-dom';
import {
    Input,
    Button,
    Typography,
} from "@material-tailwind/react";
import { Link } from "react-router-dom";
import ResetPasswordLogo from "../../assets/images/logos/XConnections_logo5.png"
import { useResetPasswordUserMutation } from "../../features/auth/authApiSlice";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


const USER_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;


const showAlertMessage = (message) => {
    toast.error(message, {
        position: 'top-right',
    });
};

const showConfirmationMessage = (message) => {
    toast.success(message, {
        position: 'top-left',
    });
};

export function ResetPassword() {

    let navigate = useNavigate();

    const userRef = useRef();
    const errRef = useRef();

    const [validName, setValidName] = useState(false);

    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [validPwd, setValidPwd] = useState(false);
    const [matchPwd, setMatchPwd] = useState('');
    const [validMatch, setValidMatch] = useState(false);
    const [errMsg, setErrMsg] = useState("");

    //const params = useParams();
    //const userId = params.id;

    const [searchParams] = useSearchParams();
    const userId = searchParams.get("id");
    const userToken = searchParams.get("token");
    
    useEffect(() => {
        setValidName(USER_REGEX.test(userId));
    }, [userId])

    useEffect(() => {
        setValidPwd(PWD_REGEX.test(newPassword));
        setValidMatch(newPassword === matchPwd);
    }, [newPassword, matchPwd])

    useEffect(() => {
        setErrMsg('');
    }, [userId, newPassword, matchPwd])
    //const [resetPasswordUser, result] = useResetPasswordUserMutation()
    //console.log("result="+JSON.stringify(result))

    const [
        resetPasswordUser, // This is the mutation trigger
        { isLoading: isUpdating }, // This is the destructured mutation result
      ] = useResetPasswordUserMutation()


    const handlePasswordReset = async (e) => {
        e.preventDefault();

        try {
            if (newPassword !== confirmNewPassword)
                toast.error(`New Password and 
                             Confirm Password do not match !`, {
                    autoClose: 5000,
                    position: "top-right",
                });
            else {
                // Execute the trigger with the `newPassword`, `userToken` and updated `userId`
                const response = resetPasswordUser({ newPassword, userToken, userId });
                console.log('Password Reset user: ', response);
                
                showConfirmationMessage("Reset Password was successful!")
                navigate('/login')
            }
        } catch (error) {
          console.error("error=", JSON.stringify(error));
          console.error('Failed to activate user: ', error?.data.message);
          // NOTE: transformErroResponse only returns status
          // console.error("Failed to activate user: ", error)
          showAlertMessage("Failed to activate user!")
        }
    }

    const handleNewPasswordInput = ({ target }) => setNewPassword(target.value);
    const handleConfirmNewPasswordInput = ({ target }) => setConfirmNewPassword(target.value);


    return (
        <div className="relative flex h-screen content-center items-center justify-center pt-16 pb-32">
        <section className="m-8 flex">
        <div className="w-2/5 h-full hidden lg:block">
            <img
            alt="logo"
            src={ResetPasswordLogo}
            className="h-full w-full object-cover rounded-3xl"
            />
        </div>
        <div className="w-full lg:w-3/5 flex flex-col items-center flex-wrap justify-center">
            <div className="mb-1 flex flex-col gap-6">
                <Typography variant="h4" color="blue-gray" className="mb-3 font-medium">
                    Reset Account Password
                </Typography>
            </div>
                <Typography variant="small" color="blue-gray" className="flex-wrap">
                  Enter a new password for your XConnections account.
                </Typography>
            <form className="mt-8 mb-2 mx-auto w-80 max-w-screen-lg lg:w-1/2">
            <div className="mb-1 flex flex-col gap-2">
                <Input
                    size="lg"
                    type="password"
                    placeholder="**********"
                    name="newpassword"
                    id="newpassword"
                    value={newPassword}
                    onChange={handleNewPasswordInput}
                    label="New Password"
                    className="w-72"
                />
                <Input
                    size="lg"
                    type="password"
                    placeholder="**********"
                    name="confirmnewpassword"
                    value={confirmNewPassword}
                    onChange={handleConfirmNewPasswordInput}
                    id="confirmnewpassword"
                    label="Confirm New Password"
                    className="w-72"
                />
            </div>
            <Button 
                className="mt-6" 
                fullWidth
                color={newPassword && confirmNewPassword ? "gray" : "blue-gray"}
                disabled={!(newPassword && confirmNewPassword)}
                onClick={handlePasswordReset}
            >            
                Reset Password
            </Button>
            <Typography variant="paragraph" className="text-center text-blue-gray-500 font-medium mt-4">
                Already a trusted partner?
                <Link to="/login" className="text-gray-900 ml-1">Login</Link>
            </Typography>
            </form>
        </div>
        </section>
        </div>
);
}

export default ResetPassword;