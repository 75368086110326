import { useSelector } from "react-redux";
import { selectCurrentToken } from "../features/auth/authSlice";

import { jwtDecode } from "jwt-decode";

const useAuth = () => {

    const token = useSelector(selectCurrentToken)

    // initialize
    let isVendor = false
    let isClient = false
    let isAdmin = false
    let status = "client"
    let username = ""
    let roles = []

    if (token) {

        const decoded = jwtDecode(token)

        const username = decoded.UserInfo.username
        const roles = decoded.UserInfo.roles
        //const expiration = decoded.UserInfo.exp
        const expiration = decoded.expires

        isVendor = roles.includes('vendor')
        isClient = roles.includes('client')
        isAdmin = roles.includes('admin')

        if (isAdmin) status = "admin"
        if (isVendor) status = "vendor"
        if (isClient) status = "client"

        return { username, roles, status, isVendor, isClient, isAdmin }
    }

    return { username, roles, status, isVendor, isClient, isAdmin }
}

export default useAuth