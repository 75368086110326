import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { useRef } from 'react';
import {
    Card,
    CardBody,
    CardHeader,
    CardFooter,
    Checkbox,
    Chip,
    Badge,
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
    IconButton,
    Input,
    Menu,
    MenuHandler,
    MenuList,
    MenuItem,
    Typography,
} from "@material-tailwind/react";
  
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import {
  ArrowDownTrayIcon,
  EllipsisHorizontalIcon,
  AdjustmentsVerticalIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/solid"

import PulseLoader from 'react-spinners/PulseLoader';

import { useGetRfpStepsQuery } from '../../features/client/rfpStepsApiSlice.js';
import { useGetRfpsQuery } from '../../features/client/clientApiSlice.js';
  
import DefaultLayout from '../../layouts/DefaultLayout';
import Breadcrumb from '../../components/Breadcrumb';
import ErrorsPage from '../auth/ErrorPage'

import TABLE_HEAD from '../../data/proposal-table-head.js';



const TableComponent = ({size, rfps}) => {

  console.log("******* TABLE COMPONENT *********")
  console.log("Table component SIZE="+size)
  console.log("Table component RFPs="+rfps)

  const ref = useRef(null);

  if (ref.current) {
    console.log("table component, ref is not null");
    console.log("ref="+ref);
  };

  let content = null;

  // TODO: should company be included in rfp_requests table
  let company = "Company";
  // TODO: how to join rfps with rfp_steps to get rfp proposal status
  let proposal_status = "New";

  const [rfpId, setRfpId] = useState(0);
  const [openMenu, setOpenMenu] = React.useState(false);


  useImperativeHandle(ref, () => {
    return {      
      getRfpId: () => rfpId,
      
    }
  }, [rfpId]);

  // TODO: use LazyQuery?
  const {
    data: rfpSteps,
    isLoading,
    isFetching,
    isSuccess,
    isError,
    error
  } = useGetRfpStepsQuery(rfpId, {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true
    })

    if (isLoading || isFetching) {
      return <PulseLoader color="#123abc" size={20} />;
    } else if (isError) {
      return <ErrorsPage />;
    } else if (isSuccess) {
      console.log("get RFP Steps data was successful!");
      console.log("data="+JSON.stringify(rfpSteps));
      let num_step = rfpSteps.length;
      content =  (
      <section className="m-10">
        <Menu
          placement="right-start"
          open={openMenu}
          handler={setOpenMenu}
          allowHover
          offset={15}
        >
          <MenuHandler>
            <Button>Select Proposals</Button>
          </MenuHandler>
          <MenuList className="max-h-[120rem] max-w-[1500rem]">
            {rfps.map(({ id, description, duration, budget, preferredTechnology, existingProject, status, domain }, index) => {
              return (
                <MenuItem
                  key={id}
                  value={id}
                  className="flex items-center gap-2"
                  onClick={() => setRfpId(id)}
                >
                  <span className="ml-auto">{description}</span>
                </MenuItem>
              );
            })}
          </MenuList>
        </Menu>
        <Card className="h-full w-full">
          <CardHeader
            floated={false}
            shadow={false}
            className="rounded-none flex flex-wrap gap-4 justify-between p-3"
          >
            <div>
              <Badge content={num_step}>
                <Button
                  color={
                    proposal_status === "Completed"
                      ? "blue-gray"
                      : proposal_status === "Pending" || proposal_status === "In Progress"
                      ? "amber"
                      : proposal_status === "Cancelled"
                      ? "red"
                      : proposal_status === "New"
                      ? "green"
                      : "red"
                  }
                >
                {company} Proposal
                </Button>
              </Badge>              
              <Typography
                variant="small"
                className="text-gray-600 font-normal mt-1"
              >
                These are details about the current proposal{" "}
              </Typography>
            </div>
            <div className="flex flex-wrap items-center w-full shrink-0 gap-4 md:w-max">
              <div className="w-full md:w-72">
                <Input
                  label="Search"
                  icon={<MagnifyingGlassIcon className="h-5 w-5" />}
                />
              </div>
              <div className="flex gap-4">
                <Button
                  color="gray"
                  variant="outlined"
                  className="flex items-center gap-1 md:max-w-fit w-full"
                >
                  filter
                  <AdjustmentsVerticalIcon className="w-4 h-4 text-gray-900" />
                </Button>
                <Button
                  color="gray"
                  variant="outlined"
                  className="flex items-center gap-1 md:max-w-fit w-full"
                >
                  export
                  <ArrowDownTrayIcon className="w-4 h-4 text-gray-900" />
                </Button>
              </div>
            </div>
          </CardHeader>
            <CardBody className="overflow-scroll !px-0 py-0">
              <table className="w-full min-w-max table-auto">
                <thead>
                  <tr>
                    {TABLE_HEAD.map(({ head, icon }) => (
                      <th
                        key={head}
                        className="border-b border-gray-300 !p-4"
                      >
                        <div className="flex gap-2 items-center">
                          {icon}
                          <Typography
                            color="blue-gray"
                            variant="small"
                            className="!font-bold"
                          >
                            {head}
                          </Typography>
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {rfpSteps.map(
                    ({ rfp_id, rfp_step_id, rfp_step_desc, start_date, end_date, status }) => {
                      const classes = "!p-4 border-b border-gray-300";
                      return (
                        <tr key={rfp_step_id}>
                          <td className={classes}>
                            <Checkbox
                              label={
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-bold"
                                >
                                  {rfp_step_id}
                                </Typography>
                              }
                            />
                          </td>
                          <td className={classes}>
                            <div className="flex items-center gap-3">
                              <Typography
                                variant="small"
                              >
                                {rfp_step_desc}
                              </Typography>
                            </div>
                          </td>                        
                          <td className={classes}>
                            <div className="w-max">
                              <Chip
                                variant="ghost"
                                value={status}
                                color={
                                  status === "Completed"
                                    ? "blue-gray"
                                    : status === "In Progress"
                                    ? "amber"
                                    : status === "New"
                                    ? "green"
                                    : "red"
                                }
                              />
                            </div>
                          </td>
                          <td className="border-b border-gray-300 text-right pr-6">
                            <Menu placement="left-start">
                              <MenuHandler>
                                <IconButton variant="text">
                                  <EllipsisHorizontalIcon className="w-8 h-8 text-gray-600" />
                                </IconButton>
                              </MenuHandler>
                              <MenuList>
                                <MenuItem>Mark Complete</MenuItem>
                              </MenuList>
                            </Menu>
                          </td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
            </CardBody>
          <CardFooter className="flex justify-between items-center">
            <Typography variant="h6" color="blue-gray">
              Page 1{" "}
              <span className="font-normal text-gray-600">of 10</span>
            </Typography>
            <div className="flex gap-4">
              <Button
                variant="outlined"
                size="sm"
                className="flex items-center gap-1"
              >
                <ChevronLeftIcon strokeWidth={3} className="h-3 w-3" />
                prev
              </Button>
              <Button
                variant="outlined"
                className="flex items-center gap-1"
              >
                next
                <ChevronRightIcon strokeWidth={3} className="h-3 w-3" />
              </Button>
            </div>
          </CardFooter>
        </Card>
      </section>
    )
    return content
  } else {
    console.log("get RFP Steps data was NOT successful, due to: "+error);
    return <ErrorsPage />;
  }
};



const KpiComponent = forwardRef((props, kpiRef) => {
  const { kpiId, ...otherProps} = props;
  console.log("******* KPI COMPONENT *********")
  console.log({...otherProps}) 
  console.log("KPI component ID="+kpiId)

  const [rfpId, setRfpId] = useState(0);
  const [open, setOpen] = useState(false);
 
  const handleOpen = () => setOpen(!open);

  const {
    data: rfpSteps,
    isLoading,
    isFetching,
    isSuccess,
    isError,
    error
  } = useGetRfpStepsQuery(props.id, {
    //pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true
    })


  useImperativeHandle(kpiRef, () => ({
    showAlert: () => {
      alert('Alert from Child Component A');
    },
    getRfpId: () => {
      console.log("Here is what I retrieved..."+rfpId)
    },
    setRfpId: (id) => {
      alert('setRfpId to:'+id);
      setRfpId(id);
      console.log("rfpId was set to="+id)
    },
    getRFPStepsData: () => {
      if (isLoading || isFetching) {
        return <PulseLoader color="#123abc" size={20} />;
      } else if (isError) {
        return <ErrorsPage />;
      } else if (isSuccess) {
        console.log("get RFP Steps data was successful!");
        return { data: rfpSteps };
      } else {
        console.log("get RFP Steps data was NOT successful, due to: "+error);
        return <ErrorsPage />;
      }
    },
    showTable: () => {
      console.log("show table was called...")
    },
    showDetails: () => {
      console.log("show details was called...")
      setOpen(!open);
      return (
        <>
          <Dialog open={open}
            handler={handleOpen}
          >
            <DialogHeader>Details</DialogHeader>
            <DialogBody divider>
              The details of the proposal
            </DialogBody>
            <DialogFooter>
              <Button
                variant="text"
                color="red"
                onClick={() => setOpen(false)}
                className="mr-1"
              >
                <span>Cancel</span>
              </Button>
              <Button variant="gradient" onClick={() => setOpen(false)}>
                <span>Confirm</span>
              </Button>
            </DialogFooter>
          </Dialog>
        </>
      )
    }
  }));

  const handleKpiClick = (id) => {
    console.log("KPI clicked for proposal: "+id);
    //kpiRef.current.showAlert();
    //kpiRef.current.setRfpId(id);

    console.log("rfpId should now be="+rfpId)
    kpiRef.current.getRFPStepsData(id);
    
  }

  return (
      <Card className="shadow-sm border border-gray-200 !rounded-lg outline-4">
        <CardBody className="p-4">
          <div className="flex justify-between items-center">
            <Typography
              className="font-medium !text-xs text-gray-600"
            >
              {props.preferredTechnology}
            </Typography>
            <Chip variant="ghost" color="green" value={props.status} />
          </div>
          <Typography
            color="blue-gray"
            className="font-medium !text-xs text-gray-600"
          >
            Domain: {props.domain}
          </Typography>
          <Typography>
            <Button 
              variant="filled"
              color="blue"
              size="sm"
              name="Submit"
              value="1"
              onClick={() => {handleKpiClick(props.id); handleOpen(props.id)}}
            >
              Details: {props.id}
            </Button> 
            <Dialog
                open={open}
                size={"md"}
                handler={handleOpen}
              >
              <DialogHeader>Details</DialogHeader>
              <DialogBody divider>
                The details of the proposal
              </DialogBody>
              <DialogFooter>
                <Button
                  variant="text"
                  color="red"
                  onClick={() => setOpen(false)}
                  className="mr-1"
                >
                  <span>Cancel</span>
                </Button>
                <Button variant="gradient" onClick={() => setOpen(false)}>
                  <span>Confirm</span>
                </Button>
              </DialogFooter>
            </Dialog> 
          </Typography>        
        </CardBody>
      </Card>

    );    
})

const RequestForProposal = () => {

    console.log("******* REQUEST FOR PROPOSAL *********")

    const {
        data: rfps,
        isLoading,
        isFetching,
        isSuccess,
        isError,
        error
      } = useGetRfpsQuery('rfpRequests', {
        //pollingInterval: 60000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
        })
    

    const kpiRef = useRef(0);
    

    let content;

    if (isLoading || isFetching) {
        return <PulseLoader color="#123abc" size={20} />;
    } else if (isSuccess) {
        console.log("get RFPs was successful!");
        content = (
            <div>
                <DefaultLayout>
                <Breadcrumb pageName='Proposals' />
                <React.Fragment>
                    <div className="mt-6 grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 items-center md:gap-2.5 gap-4">
                    {rfps.map((props, key) => (
                        <KpiComponent ref={kpiRef} key={key} {...props} />
                    ))}             
                    </div>
                    <TableComponent rfps={rfps} />
                </React.Fragment>

                </DefaultLayout>
            </div>
        )
    
        return content
    } else if (isError) {
        console.log(error)
        return <ErrorsPage />
    } else {
        return content
    }
}

export default RequestForProposal;            