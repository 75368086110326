import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@material-tailwind/react";
import { Provider } from "react-redux";
import { store } from "./api/store";
import { MaterialTailwindControllerProvider } from "./context";
import "../src/assets/css/tailwind.css";
import Modal from 'react-modal';

import { ToastContainer } from 'react-toastify';

const theme = {
  colors: {
    primary: '#007bff',
    secondary: '#6c757d',
    success: '#28a745',
    danger: '#dc3545',
    warning: '#ffc107',
    info: '#17a2b8',
    light: '#f8f9fa',
    dark: '#343a40',
  },
  input: {
    //defaultProps: {
    //  //variant: "standard",
    //  //variant: "outlined",
    //  //size: "lg",
    //  size: "md",
    //  color: "red",
    //  //labelProps: {
    //  //  className: "text-red-500"
    //  //},
    //  className:"bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 pr-10 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500",
    //  //className:"!border !border-blue-300 bg-white text-blue-900 shadow-lg shadow-gray-900/5 ring-4 ring-transparent placeholder:text-gray-500 focus:!border-gray-900 focus:!border-t-gray-900 focus:ring-gray-900/10",
    //  //className:"... ring-2 ring-blue-500",
    //  //className:"ring-2 ring-blue-500/50",
    //  labelProps:{
    //    //className: "mb-2.5 block font-medium text-black dark:text-white",
    //    //className: "font-medium text-indigo-500 dark:text-black",
    //    className: "text-red-500",
    //    //className: "hidden",
    //  },
    //  containerProps:{ className: "min-w-[100px]" },
    //},
    defaultProps: {
      //variant: "outlined",
      //size: "md",
      //color: "blue",
      //className: "bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 pr-10 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500",
      //labelProps: {
      //  className: "font-medium text-black dark:text-white",
      //  //className: "mb-2.5 block font-medium text-black dark:text-white",
      //},
      //labelProps: {
      //  className: "absolute left-1 -top-2 bg-white px-1 text-sm text-blue-500 dark:text-blue-400 dark:bg-gray-800 z-10",
      //},
      //containerProps: { 
      //  className: "min-w-[100px] relative mt-4" 
      //},
    },
    styles: {
      base: {
        input: {
          peer: "peer",
          width: "w-full",
          height: "h-full",
          fontFamily: "font-sans",
          fontWeight: "font-normal",
          fontSize: "text-base",
          borderWidth: "border-2",
          borderColor: "border-gray-300",
          borderRadius: "rounded-md",
          transition: "transition-all",
          bg: "bg-transparent",
          color: "text-gray-900 dark:text-gray-100",
          outline: "outline-none",
          focusBorderColor: "focus:border-blue-500",
          focusBorderWidth: "focus:border-2",
          focusRing: "focus:ring-0 focus:ring-blue-500 focus:ring-opacity-50",
          placeholder: "placeholder-gray-400",
          disabled: "disabled:bg-gray-100 disabled:border-gray-300 disabled:text-gray-500",
        },
      },
    },
  },
}

Modal.setAppElement('#root')
ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <Provider store={store}>
      <ToastContainer />
      <BrowserRouter>
        <ThemeProvider value={theme}>
          <MaterialTailwindControllerProvider>
            <Routes>
              <Route path="/*" element={<App />} />
            </Routes>
          </MaterialTailwindControllerProvider>
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);